import React, { Fragment, useEffect, useContext } from "react";
import CustomizerContext from "../../_helper/Customizer";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { H1, H5, P } from '../../AbstractElements';
import {
  DocumentScanner as ScannerIcon,
  FileUpload as UploadIcon,
  AddCircle as AddCircleIcon,
  NoteAdd as NoteAddIcon,
  TaskAlt as TaskAltIcon,
  Verified as VerifiedIcon,
} from '@mui/icons-material';
import { Link } from "react-router-dom";


const ButtonCard = ({ path, icon, title, body, disabled }) => (
  <Col sm="3" className="d-flex flex-fill align-items-stretch">
    <Link to={`${process.env.PUBLIC_URL}/${path}`}>
      <Card className='widget-hover Typeahead-selectable mb-0' style={{ height: '100%', boxShadow: 'none' }}>
        <CardBody>
          <div>
            {icon}
            <H5 attrH5={{ className: `mb-2 mt-3`, style: {color: '#191923'} }}>{title}</H5>
            <P attrPara={{ style: { color: '#52526c' } }}>{body}</P>
          </div>
        </CardBody>
      </Card>
    </Link>
  </Col>
)

const DisabledButtonCard = ({ path, icon, title, body, disabled }) => (
  <Col sm="3" className="d-flex flex-fill align-items-stretch">
    <Card className='mb-0' style={{ height: '100%', boxShadow: 'none' }}>
      <CardBody>
        <div style={{
          position: 'absolute',
          right: '0px',
          padding: '0.25rem 0.5rem',
          marginRight: '0.5rem',
          backgroundColor: 'orange',
          borderRadius: '8px',
          color: '#FFF',
        }}>
          <span>Coming Soon</span>
        </div>
        <div>
          {icon}
          <H5 attrH5={{ className: `mb-2 mt-3`, style: {color: '#191923'} }}>{title}</H5>
          <P attrPara={{ style: { color: '#52526c' } }}>{body}</P>
        </div>
      </CardBody>
    </Card>
  </Col>
)

const Home = () => {
  const { setHeaderMenu } = useContext(CustomizerContext);

  useEffect(() => {
    setHeaderMenu('Scan & Create')
  }, [])

  return (
    <Fragment>
      <Container
        fluid={true}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <H1 attrH1={{ className: "mb-4" }}>Pick what you need to process!</H1>

        <Row className="row d-flex flex-wrap">
          <ButtonCard
            path="scan/id/Dubai"
            icon={<ScannerIcon style={{ fontSize: 30, color: '#3083e1' }} />}
            title="Scan ID"
            body="Extract details from IDs instantly."
          />
          <ButtonCard
            path="verify/id/Dubai"
            icon={<TaskAltIcon style={{ fontSize: 30, color: '#62b149' }} />}
            title="Verify ID"
            body="Verify IDs with government databases."
          />
          <ButtonCard
            path="scan/document/Dubai"
            icon={<UploadIcon style={{ fontSize: 30, color: '#c94141' }} />}
            title="Scan Akta"
            body="Get essential info from legal deeds."
          />
          <ButtonCard
            path="create/document/:layout"
            icon={<NoteAddIcon style={{ fontSize: 30, color: '#c0c0c9' }} />}
            title="Create Akta"
            body="Create New legal deeds."
          />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Home;

import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import CustomizerContext from "../../_helper/Customizer";

import { Breadcrumbs } from "../../AbstractElements";

import API from '../../api/services'

import Upload from './Upload';
import Loading from './Loading';
import EditIdentifier from './EditIdentifier';
import PreviewId from "./PreviewId";

import './identifier.css'

const MOCKUP_DATA_KTP = [
  {
    "ktpPhoto": "blob:http://localhost:3000/194b0036-2a8a-4229-996c-0ff36e191631",
    "alamat": "PERUMAHAN BUMI MAS RAYA\nBLOK B.1 NO.1",
    "jenisKelamin": "TUAN",
    "kecamatan": "TANGERANG",
    "kelDesa": "CIKOKOL",
    "kotaKabupaten": "KOTA TANGERANG",
    "nik": "3173011308950010",
    "nama": "ARTHUR BACHTIAR GUNAWAN",
    "pekerjaan": "PELAJAR MAHASISWA",
    "provinsi": "PROVINSI BANTEN",
    "rt": "001",
    "rw": "000",
    "rtRw": "001/000",
    "tempatDibuat": "KOTA TANGERAN",
    "tempatLahir": "JAKARTA",
    "tglLahir": "13-08-1995",
    "tglLahir_terbilang": "tiga belas Agustus seribu sembilan ratus sembilan puluh lima"
  },
  {
    "ktpPhoto": "blob:http://localhost:3000/bc70447b-0c74-4957-8547-1be1e6a85957",
    "alamat": "PERUMAHAN BUMI MAS RAYA\nBLOK B.1 NO.1",
    "jenisKelamin": "TUAN",
    "kecamatan": "TANGERANG",
    "kelDesa": "CIKOKOL",
    "kotaKabupaten": "KOTA TANGERANG",
    "nik": "3173011308950010",
    "nama": "ARTHUR BACHTIAR GUNAWAN",
    "pekerjaan": "PELAJAR MAHASISWA",
    "provinsi": "PROVINSI BANTEN",
    "rt": "001",
    "rw": "000",
    "rtRw": "001/000",
    "tempatDibuat": "KOTA TANGERAN",
    "tempatLahir": "JAKARTA",
    "tglLahir": "13-08-1995",
    "tglLahir_terbilang": "tiga belas Agustus seribu sembilan ratus sembilan puluh lima"
  }
];

const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

const Identifier = () => {
  const { setHeaderMenu } = useContext(CustomizerContext);
  const [ktpData, setKtpData] = useState([]);
  const [isProcessingOcr, setIsProcessingOcr] = useState(false);
  const [isFinishedOcr, setIsFinishedOcr] = useState(false);

  const [isProcessGenerating, setIsProcessGenerating] = useState(false);
  const [isFinishGenerate, setIsFinishGenerate] = useState(false);

  const [isSavingParagraph, setISavingParagraph] = useState(false);

  useEffect(() => {
    setHeaderMenu('Scan ID')
  }, [])

  const handleUploadKtp = async (files) => {
    let previewKtpData = [];
    let photos = [];

    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      if (file && file.file.type.startsWith('image/')) {
        previewKtpData = [...previewKtpData, file.preview]
        photos = [...photos, await getBase64(file.file)]
      }
    }

    console.log('previewKtpData', previewKtpData)
    console.log('photos', photos)

    setIsProcessingOcr(true);

    try {
      const response = await API.ktp.uploadKtp(photos); // Call the login API
      const result = response.extractedData;

      let processedData = [];
      for (let i = 0; i < photos.length; i++) {
        let jenisKelamin = result[i]?.jenisKelamin;
        if (jenisKelamin === "TUAN") {
          jenisKelamin = "LAKI-LAKI";
        } else if (jenisKelamin === "NYONYA") {
          jenisKelamin = "PEREMPUAN";
        }

        const newData = {
          ...result[i],
          ktpPhoto: result[i].ktpFilePath,
          gelar: result[i]?.jenisKelamin,
          jenisKelamin,
        };
        processedData = [...processedData, newData];
      }
      console.log('processedData', processedData)

      setKtpData(processedData);
      
      setIsProcessingOcr(false);
      setIsFinishedOcr(true);
      
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(error);
    }
  };

  const saveDataKtp = async(data) => {
    setIsFinishedOcr(false);
    setIsProcessGenerating(true);

    API.ktp.updateKtp(data).then(() => {
      setIsProcessGenerating(false);
      setIsFinishGenerate(true)
    });
  }

  const handleSaveParagraph = async(data) => {
    console.log('data', data)
    setIsFinishGenerate(false);
    setISavingParagraph(true);

    API.ktp.saveParagraph(data).then(() => {
      setKtpData([]);
      setISavingParagraph(false)
    });
  }

  return (
    <Fragment>
      <Container
        fluid={true}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        {ktpData.length === 0 && !isProcessingOcr && (
          <Upload
            title="Upload & Scan ID"
            subtitle="Kamu bisa melakukan Scan ID lebih dari satu file"
            onSubmit={handleUploadKtp}
          />
        )}

        {isProcessingOcr && (
          <Loading
            title="Processing Magic"
            body="AI OCR is decoding your data in no time."
          />
        )}

        {ktpData.length > 0 && isFinishedOcr && (
          <EditIdentifier
            data={ktpData}
            onDone={saveDataKtp}
          />
        )}

        {isProcessGenerating && (
          <Loading
            title="Almost There!"
            body="AI is working wonders to create your data."
          />
        )}

        {isFinishGenerate && (
          <PreviewId
            data={ktpData}
            onSave={handleSaveParagraph}
          />
        )}

        {isSavingParagraph && (
          <Loading
            title="Saving in Progress!"
            body="AI is safeguarding your data in our secure database."
          />
        )}

        {/* <Row>
          <Col sm="12">
            {isFinishedOcr && (
              <EditIdentifier
                data={ktpData}
                onDone={saveDataKtp}
              />
            )}

            {isFinishGenerate && (
              <PreviewId
                data={ktpData}
                onBackPress={() => setIsFinishGenerate(false)}
              />
            )}

            {!isProcessingOcr && !isFinishedOcr && (
              <HomePage
                openFileUpload={openFileUpload}
                openKtpUpload={openKtpUpload}
              /> 
            )}

            <input
              type="file"
              accept="image/*"
              ref={ktpFileInputRef}
              style={{ display: 'none' }} // Hide the input element
              onChange={handleKtpFileChange}
            />
          </Col>
        </Row> */}
      </Container>
    </Fragment>
  );
};

export default Identifier;

// Inline styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '1rem',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    fontSize: '18px',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  subtext: {
    fontSize: '14px',
    color: '#888',
    marginBottom: '20px',
  },
  filesPreviewContainer: {
    display: 'flex',
    gap: '15px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  fileCard: {
    width: '15rem',
    height: '10rem',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    backgroundColor: '#000',
    padding: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  fileName: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#FFF'
  },
  fileSize: {
    color: '#888',
    fontSize: '10px',
  },
  removeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    background: "#FFFFFF",
    cursor: "pointer",
    color: "rgb(255, 95, 95)",
    zIndex: 1,
    padding: "0",
    margin: "0",
    border: "0",
    borderRadius: "50%",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
  },
  dropzone: {
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#f2f2f5',
    cursor: 'pointer',
    color: '#666',
    height: '15rem',
    textAlign: 'center',
    alignContent: 'center',
  },
  browse: {
    textDecoration: 'underline',
    color: '#007bff',
    cursor: 'pointer',
  },
  orText: {
    marginTop: '10px',
    fontSize: '12px',
    color: '#555',
  },
  scanButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};
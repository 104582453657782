import React, { Fragment, useEffect, useContext } from "react";
import CustomizerContext from "../../_helper/Customizer";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { H1, H5, P } from '../../AbstractElements';
import {
  DocumentScanner as ScannerIcon,
  FileUpload as UploadIcon,
  AddCircle as AddCircleIcon,
  NoteAdd as NoteAddIcon,
  TaskAlt as TaskAltIcon,
  Verified as VerifiedIcon,
} from '@mui/icons-material';
import { Link } from "react-router-dom";


const CrowdSignal = () => {
  return (
    <iframe
      frameBorder="0"
      width="100%"
      height="600"
      scrolling="auto"
      allowTransparency="true"
      src="https://deveac5aba82560.survey.fm/test?iframe=1"
      title="CrowdSignal Survey"
    >
      <a href="https://deveac5aba82560.survey.fm/test">View Survey</a>
    </iframe>
  );
}

const ButtonCard = ({ path, icon, title, body, disabled }) => (
  <Col sm="3" className="d-flex flex-fill align-items-stretch">
    <Link to={`${process.env.PUBLIC_URL}/${path}`} disabled={disabled}>
      <Card className='widget-hover Typeahead-selectable mb-0' style={{ height: '100%' }}>
        <CardBody>
          <div>
            {icon}
            <H5 attrH5={{ className: `mb-2 mt-3`, style: {color: '#191923'} }}>{title}</H5>
            <P attrPara={{ style: { color: '#52526c' } }}>{body}</P>
          </div>
        </CardBody>
      </Card>
    </Link>
  </Col>
)

const Home = () => {
  const { setHeaderMenu } = useContext(CustomizerContext);

  useEffect(() => {
    setHeaderMenu('Explore Notapos')
  }, [])

  return (
    <Fragment>
      <Container
        fluid={true}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <H1 attrH1={{ className: "mb-4" }}>Hi there!</H1>

        <Row>
          <ButtonCard
            path="list/id/Dubai"
            icon={<ScannerIcon style={{ fontSize: 30, color: '#3083e1' }} />}
            title="List ID"
            body="Extract details from IDs instantly."
          />
          <ButtonCard
            path="list/document/Dubai"
            icon={<TaskAltIcon style={{ fontSize: 30, color: '#62b149' }} />}
            title="List Akta"
            body="Verify IDs with government databases."
          />
          {/* <ButtonCard
            path="list/company/Dubai"
            icon={<UploadIcon style={{ fontSize: 30, color: '#c94141' }} />}
            title="List Company"
            body="Get essential info from legal deeds."
          /> */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default Home;

import React, { Fragment, useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import CustomizerContext from "../../_helper/Customizer";
import API from '../../api/services'

const MENU_ITEMS = [
  {
    Items: [
      {
        title: "Explore Notapos",
        icon: "home",
        type: "link",
        badge: "badge badge-light-primary",
        active: false,
        path: `${process.env.PUBLIC_URL}/home`,
      },
      {
        title: "Scan & Create",
        icon: "home",
        type: "link",
        badge: "badge badge-light-primary",
        active: false,
        path: `${process.env.PUBLIC_URL}/scan-create`,
      },
    ],
  },
];

const groupAndFormatData = (data) => {
  const today = new Date();
  const categories = [
    { title: "Today", icon: "home", type: "date", active: false, items: [] },
    { title: "Yesterday", icon: "home", type: "date", active: false, items: [] },
    { title: "Previous 7 Days", icon: "home", type: "date", active: false, items: [] },
    { title: "Previous 30 Days", icon: "home", type: "date", active: false, items: [] },
  ];

  // Helper function to calculate the difference in days
  const getDateDifference = (date) => {
    const createdDate = new Date(date);
    const timeDiff = today - createdDate;
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Difference in days
  };

  // Helper function to group by category and remove duplicates
  const addToCategory = (categoryIndex, item) => {
    const category = categories[categoryIndex];
    const existingItem = category.items.find((i) => i.url === item.url);

    if (!existingItem || new Date(item.created_at) > new Date(existingItem.created_at)) {
      category.items = category.items.filter((i) => i.url !== item.url); // Remove older duplicate
      category.items.push(item); // Add the newest item
    }
  };

  // Group and remove duplicates based on URL
  data.forEach((item) => {
    const daysDiff = getDateDifference(item.created_at);

    if (daysDiff === 0) {
      addToCategory(0, item);
    } else if (daysDiff === 1) {
      addToCategory(1, item);
    } else if (daysDiff <= 7) {
      addToCategory(2, item);
    } else if (daysDiff <= 30) {
      addToCategory(3, item);
    }
  });

  // Format the final structure
  const result = [];
  categories
    .filter((category) => category.items.length > 0) // Remove empty categories
    .forEach((category) => {
      result.push({
        title: category.title,
        icon: category.icon,
        type: category.type,
        active: category.active,
      });

      category.items.forEach((item) => {
        result.push({
          title: item.name,
          icon: "home",
          type: "link",
          active: false,
          path: `${process.env.PUBLIC_URL}/${item.url}`, // Adjust the path logic if needed
        });
      });
  });

  return result;
}

const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive, activeClass }) => {
  const { layout } = useContext(CustomizerContext);
  const [listMenu, setListMenu] = useState(MENU_ITEMS);

  useEffect(() => {
    API.history.getListHistory()
      .then(x => {
        const historyMenu = {
          menutitle: "History",
          Items: groupAndFormatData(x.data)
        };

        console.log('x.data', x.data)
        
        setListMenu([
          ...MENU_ITEMS,
          historyMenu,
        ])
      })
      .catch(x => console.log(x))
  }, [])

  const layout1 = localStorage.getItem("sidebar_layout") || layout;

  const id = window.location.pathname.split("/").pop();
  const layoutId = id;
  const CurrentPath = window.location.pathname;

  const { t } = useTranslation();
  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon ";
      // document.querySelector('.mega-menu-container').classList.remove('d-block');
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper";
      }
    }
    if (!item.active) {
      listMenu.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: listMenu });
  };

  return (
    <>
      {listMenu.map((Item, i) => (
        <Fragment key={i}>
          {Item?.menutitle && (
            <li className="sidebar-main-title">
              <div>
                <h6 className="lan-1">{t(Item.menutitle)}</h6>
              </div>
            </li>
          )}
          {Item.Items.map((menuItem, i) => (
            <li className="sidebar-list" key={i}>
              {menuItem.type === "date" && (
                <a
                  href="#"
                  className={`sidebar-link sidebar-title ${CurrentPath.includes(menuItem.title.toLowerCase()) ? "active" : ""} ${menuItem.active && "active"}`}
                  style={{ cursor: 'auto' }}
                  disabled
                >
                  <SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
                  <SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
                  <span style={{ color: 'rgb(75 75 90)' }}>{t(menuItem.title)}</span>
                </a>
              )}

              {menuItem.type === "sub" ? (
                <a
                  href="javascript"
                  className={`sidebar-link sidebar-title ${CurrentPath.includes(menuItem.title.toLowerCase()) ? "active" : ""} ${menuItem.active && "active"}`}
                  onClick={(event) => {
                    event.preventDefault();
                    setNavActive(menuItem);
                    activeClass(menuItem.active);
                  }}>
                  <SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
                  <SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
                  <span>{t(menuItem.title)}</span>
                  {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                  <div className="according-menu">{menuItem.active ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>}</div>
                </a>
              ) : (
                ""
              )}

              {menuItem.type === "link" && (
                <Link to={menuItem.path + "/" + layoutId} className={`sidebar-link sidebar-title link-nav  ${CurrentPath.includes(menuItem.title.toLowerCase()) ? "active" : ""}`} onClick={() => toggletNavActive(menuItem)}>
                  <SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
                  <SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
                  <span style={{ color: '#828291', fontWeight: 100 }}>{t(menuItem.title)}</span>
                </Link>
              )}
            </li>
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default SidebarMenuItems;

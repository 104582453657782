import { H3, H5, P } from '../../../AbstractElements';
import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Button, FormGroup, Input } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import { saveAs } from 'file-saver';
import './markdown-style.css';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import API from '../../../api/services';

const PTForm = () => {
    const [aktaType, setAktaType] = useState('');

    const currentDate = new Date();
    const getCurrentDate = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, "0");
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const year = now.getFullYear();

        return `${day}-${month}-${year}`;
    };


    const numbersInWords = [
        "nol", "satu", "dua", "tiga", "empat", "lima", "enam", "tujuh", "delapan", "sembilan",
        "sepuluh", "sebelas", "dua belas", "tiga belas", "empat belas", "lima belas",
        "enam belas", "tujuh belas", "delapan belas", "sembilan belas", "dua puluh"
    ];
    const getIndonesianDayName = (dateString) => {
        const daysInIndonesian = [
            "Minggu",    // Sunday
            "Senin",     // Monday
            "Selasa",    // Tuesday
            "Rabu",      // Wednesday
            "Kamis",     // Thursday
            "Jumat",     // Friday
            "Sabtu"      // Saturday
        ];

        const date = new Date(dateString);
        const dayIndex = date.getDay(); // Get the day index (0 for Sunday, 6 for Saturday)
        return daysInIndonesian[dayIndex];
    };


    // Convert a number to terbilang
    const toTerbilang = (num) => {
        if (num <= 20) return numbersInWords[num];
        const tens = Math.floor(num / 10);
        const units = num % 10;
        return units === 0
            ? `${numbersInWords[tens]} puluh`
            : `${numbersInWords[tens]} puluh ${numbersInWords[units]}`;
    };

    // Convert HH:MM to terbilang format
    const timeToTerbilang = (time) => {
        const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));
        const hoursInWords = `pukul ${toTerbilang(hours)}`;
        const minutesInWords =
            minutes === 0 ? "" : `${toTerbilang(minutes)} menit`;

        return `${hoursInWords} ${minutesInWords}`.trim();
    };

    const formatCurrency = (value) => {
        if (!value) return '';
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Add thousands separator
    };

    const parseCurrency = (value) => {
        return value.replace(/\./g, ''); // Remove thousands separator
    };

    // Convert a date to its terbilang in Indonesian
    const dateToTerbilang = (dateString) => {
        const monthsInWords = [
            "Januari", "Februari", "Maret", "April", "Mei", "Juni",
            "Juli", "Agustus", "September", "Oktober", "November", "Desember"
        ];

        const [day, month, year] = dateString.split("-").map((part) => parseInt(part, 10));

        const dayInWords = `tanggal ${toTerbilang(day)}`;
        const monthInWords = `bulan ${monthsInWords[month - 1]}`;
        const yearInWords = `tahun ${toTerbilang(Math.floor(year / 1000))} ribu ${year % 1000 !== 0 ? toTerbilang(year % 1000) : ""
            }`;

        return `${dayInWords} ${monthInWords} ${yearInWords}`.trim();
    };


    const formattedTime = `${String(currentDate.getHours()).padStart(2, "0")}:${String(
        currentDate.getMinutes()
    ).padStart(2, "0")}`;
    const [formData, setFormData] = useState({
        namaNotaris: 'Jonathan Hermawan, Sarjana Hukum, Magister Kenotariatan',
        lokasiNotaris: 'Kabupaten Tangerang',
        namaPT: '',
        nomorPT: '',
        alamatPT: '',
        tanggal: getCurrentDate(),
        jam: formattedTime,
        modalDasar: '',
        modalSetorPT: '',
        lembarSaham: '',
    });
    const [kbliList, setKbliList] = useState([]); // List of available KBLI entries
    const [selectedKbli, setSelectedKbli] = useState(""); // Selected KBLI ID from dropdown
    const [addedKbli, setAddedKbli] = useState([]); // List of added KBLI entries
    useEffect(() => {
        // Replace this with the data loaded from the backend or props
        const data = [
            {
                "KBLI": "21013",
                "JudulKBLI": "INDUSTRI PRODUK FARMASI UNTUK HEWAN",
                "UraianKBLI": "Kelompok ini mencakup usaha pembuatan, pengolahan dan pengemasan ulang obat-obatan yang berbentuk jadi (sediaan) untuk hewan, misalnya dalam bentuk serbuk, tablet, kapsul, salep, larutan, suspensi, aerosol dan lainnya. Termasuk industri produk benang bedah, industri alat-alat diagnosa medis, industri produksi radioisotop untuk radiofarmaka, industri farmasi bioteknologi dan industri pembalut medis, perban dan sejenisnya yang dikhususkan untuk hewan."
            },
            {
                "KBLI": "26391",
                "JudulKBLI": "INDUSTRI KARTU CERDAS (SMART CARD)",
                "UraianKBLI": "Kelompok ini mencakup pembuatan smart card, seperti perencanaan design chip (design house), perencanaan tata letak sirkuit smart card, industri chip foundry, industri chip module packaging, pembuatan firmware dan software yang berkaitan langsung dengan smart card, chip packaging. Contoh smart card seperti simcard, Near Field Communications (NFC), dengan teknologi 2G, 3G, 4G, 5G dst, kartu perbankan, kartu akses, micro dan macro payment, kartu kredit dan semua kartu yang menggunakan pengaman data dan atau komunikasi."
            },
            {
                "KBLI": "35111",
                "JudulKBLI": "PEMBANGKITAN TENAGA LISTRIK",
                "UraianKBLI": "Kelompok ini mencakup usaha memproduksi tenaga listrik melalui pembangkitan tenaga listrik yang menggunakan berbagai jenis sumber energi. Sumber energi fosil seperti batubara, gas, bahan bakar minyak, dan diesel. Sumber energi terbarukan seperti panas bumi, angin, bioenergi, sinar matahari, aliran dan terjunan air, gerakan dan perbedaan suhu lapisan laut. Sumber energi hybrid yang menggabungankan sumber energi fosil dengan energi terbarukan, dan energi yang berasal dari teknologi energy storage."
            },
            {
                "KBLI": "43213",
                "JudulKBLI": "INSTALASI ELEKTRONIKA",
                "UraianKBLI": "Kelompok ini mencakup kegiatan pemasangan instalasi elektronika pada bangunan gedung baik untuk hunian maupun non hunian, dan elektronika bandara serta teknologi informasi (termasuk telekomunikasi dan sistem teknologi informasi), seperti pemasangan sistem alarm, close circuit TV dan sound system dan commercial management system (pre-paid electricity voucher). Termasuk juga instalasi access control, scoring board, timing system, perimeter pixel display, master clock dan fasilitas elektronik lainnya."
            },
            {
                "KBLI": "49426",
                "JudulKBLI": "ANGKUTAN SEWA KHUSUS",
                "UraianKBLI": "Kelompok ini mencakup usaha pelayanan angkutan dari pintu ke pintu dengan pengemudi, menggunakan kendaraan bermotor umum (sedan\/bukan sedan), memiliki wilayah operasi dalam wilayah perkotaan, dari dan ke bandar udara, pelabuhan, atau simpul transportasi lainnya serta pemesanan menggunakan aplikasi berbasis teknologi informasi, dengan besaran tarif tercantum dalam aplikasi. Kelompok ini tidak mencakup angkutan taksi (49421) dan angkutan ojek motor (49424)."
            },
            {
                "KBLI": "61100",
                "JudulKBLI": "AKTIVITAS TELEKOMUNIKASI DENGAN KABEL",
                "UraianKBLI": "Kelompok ini mencakup kegiatan pengoperasian, pemeliharaan atau penyediaan akses pada fasilitas untuk pengiriman suara, data, teks, bunyi dan video dengan menggunakan infrastruktur kabel telekomunikasi, seperti pengoperasian dan perawatan fasilitas pengubahan dan pengiriman untuk menyediakan komunikasi titik ke titik melalui saluran darat, gelombang mikro atau perhubungan saluran data dan satelit, pengoperasian sistem pendistribusian kabel (yaitu untuk pendistribusian data dan sinyal televisi) dan pelengkapan telegrap dan komunikasi non vocal lainnya yang menggunakan fasilitas sendiri. Dimana fasilitas transmisi yang melakukan kegiatan ini, bisa berdasarkan teknologi tunggal atau kombinasi dari berbagai teknologi. Termasuk pembelian akses dan jaringan kapasitas dari pemilik dan operator dari jaringan dan menyediakan jasa telekomunikasi yang menggunakan kapasitas ini untuk usaha dan rumah tangga dan penyediaan akses internet melalui operator infrastruktur dengan kabel. Kegiatan penyelenggaraan jaringan untuk telekomunikasi tetap yang dimaksudkan bagi terselenggaranya telekomunikasi publik dan sirkuit sewa. Termasuk kegiatan sambungan komunikasi data yang pengirimannya dilakukan secara paket, melalui suatu sentral atau melalui jaringan lain, seperti Public Switched Telephone Network (PSTN). Termasuk juga kegiatan penyelenggaraan jaringan teristerial yang melayani pelanggan bergerak tertentu antara lain jasa radio trunking dan jasa radio panggil untuk umum."
            },
            {
                "KBLI": "61200",
                "JudulKBLI": "AKTIVITAS TELEKOMUNIKASI TANPA KABEL",
                "UraianKBLI": "Kelompok ini mencakup kegiatan penyelenggaraan jaringan yang melayani telekomunikasi bergerak dengan teknologi seluler di permukaan bumi. Kegiatannya mencakup pengoperasian, pemeliharaan atau penyediaan akses pada fasilitas untuk mentranmisikan suara, data, teks, bunyi dan video menggunakan infrastruktur komunikasi tanpa kabel dan pemeliharaan dan pengoperasian nomor panggil (paging), seperti halnya jaringan telekomunikasi selular dan telekomunikasi tanpa kabel lainnya. Fasilitas transmisi menyediakan transmisi omni-directional melalui gelombang udara yang dapat berdasarkan teknologi tunggal atau kombinasi beberapa teknologi. Termasuk pembelian akses dan kapasitas jaringan dari pemilik dan operator jaringan serta menyediakan jasa jaringan tanpa kabel (kecuali satelit) untuk kegiatan bisnis dan rumah tangga dan penyediaan akses internet melalui operator infrastruktur jaringan tanpa kabel."
            },
            {
                "KBLI": "62014",
                "JudulKBLI": "AKTIVITAS PENGEMBANGAN TEKNOLOGI BLOCKCHAIN",
                "UraianKBLI": "Kelompok ini mencakup kegiatan pengembangan teknologiblockchain, seperti kegiatanimplementasi smart contract,perancangan infrastrukturblockchainpublik danblockchainprivat. Kelompok ini tidak mencakup perdagangan berjangka komoditas aset kripto (6615)."
            },
            {
                "KBLI": "62015",
                "JudulKBLI": "AKTIVITAS PEMROGRAMAN BERBASIS KECERDASAN ARTIFISIAL",
                "UraianKBLI": "Kelompok ini mencakup konsultasi yang dilanjutkan analisis dan pemrograman yang memanfaatkan teknologi kecerdasan artifisial (AI) termasuk subset dari AI seperti machine learning, natural language processing, expert system, dan subset AI lainnya."
            },
            {
                "KBLI": "62029",
                "JudulKBLI": "AKTIVITAS KONSULTASI KOMPUTER DAN MANAJEMEN FASILITAS KOMPUTER LAINNYA",
                "UraianKBLI": "Kelompok ini mencakup usaha konsultasi tentang tipe dan konfigurasi dari perangkat keras komputer dengan atau tanpa dikaitkan dengan aplikasi piranti lunak. Perencanaan dan perancangan sistem komputer yang mengintegrasikan perangkat keras, piranti lunak dan teknologi komunikasi komputer. Konsultasi biasanya menyangkut analisis kebutuhan pengguna komputer dan permasalahannya, serta memberikan jalan keluar yang terbaik. Unit yang diklasifikasikan dalam subgolongan ini dapat menyediakan komponen sistem perangkat keras dan piranti lunak sebagai bagian dari jasa yang terintegrasi atau komponen ini dapat disediakan oleh pihak ketiga atau vendor. Unit yang diklasifikasikan dalam subgolongan ini pada umumnya menginstal sistem dan melatih serta mendukung pengguna sistem. Termasuk penyediaan manajemen dan pengoperasian sistem komputer klien dan\/atau fasilitas pengolahan data di tempat klien, demikian juga jasa pendukung terkait."
            },
            {
                "KBLI": "62090",
                "JudulKBLI": "AKTIVITAS TEKNOLOGI INFORMASI DAN JASA KOMPUTER LAINNYA",
                "UraianKBLI": "Kelompok ini mencakup kegiatan teknologi informasi dan jasa komputer lainnya yang terkait dengan kegiatan yang belum diklasifikasikan di tempat lain, seperti pemulihan kerusakan komputer, instalasi (setting up) personal komputer dan instalasi perangkat lunak. Termasuk juga kegiatan manajemen insiden dan digital forensik. Kelompok ini mencakup berbagai usaha yang berkaitan dengan komputer yang belum tercakup dalam golongan 6201-6202."
            },
            {
                "KBLI": "64951",
                "JudulKBLI": "LAYANAN PINJAM MEMINJAM UANG BERBASIS TEKNOLOGI INFORMASI (FINTECH P2P LENDING) KONVENSIONAL",
                "UraianKBLI": "Kelompok ini mencakup usaha layanan pinjam meminjam uang berbasis teknologi informasi (fintech peer to peer lending) yang diselenggarakan secara konvensional, dengan kegiatan usaha meliputi penyediaan, pengelolaan, dan pengoperasian layanan jasa keuangan untuk mempertemukan pemberi pinjaman dengan penerima pinjaman dalam rangka melakukan perjanjian pinjam meminjam dalam mata uang rupiah secara langsung melalui sistem elektronik dengan menggunakan jaringan internet."
            },
            {
                "KBLI": "64951",
                "JudulKBLI": "LAYANAN PINJAM MEMINJAM UANG BERBASIS TEKNOLOGI INFORMASI (FINTECH P2P LENDING) KONVENSIONAL",
                "UraianKBLI": "Kelompok ini mencakup usaha layanan pinjam meminjam uang berbasis teknologi informasi (fintech peer to peer lending) yang diselenggarakan secara konvensional, dengan kegiatan usaha meliputi penyediaan, pengelolaan, dan pengoperasian layanan jasa keuangan untuk mempertemukan pemberi pinjaman dengan penerima pinjaman dalam rangka melakukan perjanjian pinjam meminjam dalam mata uang rupiah secara langsung melalui sistem elektronik dengan menggunakan jaringan internet."
            },
            {
                "KBLI": "64952",
                "JudulKBLI": "LAYANAN PINJAM MEMINJAM UANG BERBASIS TEKNOLOGI INFORMASI (FINTECH P2P LENDING) SYARIAH",
                "UraianKBLI": "Kelompok ini mencakup usaha layanan pinjam meminjam uang berbasis teknologi informasi (fintech peer to peer lending) yang diselenggarakan seluruhnya berdasarkan prinsip syariah, dengan kegiatan usaha meliputi penyediaan, pengelolaan, dan pengoperasian layanan jasa keuangan untuk mempertemukan pemberi pinjaman dengan penerima pinjaman dalam rangka melakukan akad pembiayaan dalam mata uang rupiah secara langsung melalui sistem elektronik dengan menggunakan jaringan internet."
            },
            {
                "KBLI": "64953",
                "JudulKBLI": "UNIT USAHA SYARIAH LAYANAN PINJAM MEMINJAM UANG BERBASIS TEKNOLOGI INFORMASI (FINTECH P2P LENDING)",
                "UraianKBLI": "Kelompok ini mencakup kegiatan unit kerja dari kantor pusat penyelenggara layanan pinjam meminjam uang berbasis teknologi informasi (fintech peer to peer lending) konvensional yang melaksanakan kegiatan usaha layanan pinjam meminjam uang berbasis teknologi informasi (fintech peer to peer lending) berdasarkan prinsip syariah dan\/atau berfungsi sebagai kantor induk dari kantor yang melaksanakan kegiatan usaha berdasarkan prinsip syariah."
            },
            {
                "KBLI": "66118",
                "JudulKBLI": "PENYELENGGARA PENAWARAN EFEK MELALUI LAYANAN URUN DANA BERBASIS TEKNOLOGI INFORMASI (SECURITIES CROWDFUNDING)",
                "UraianKBLI": "Kelompok ini mencakup kegiatan pihak yang menyediakan, mengelola, dan mengoperasikan penyelenggaraan penawaran Efek oleh Penerbit yang secara langsung kepada pemodal melalui jaringan sistem elektronik yang bersifat terbuka."
            },
            {
                "KBLI": "66131",
                "JudulKBLI": "PENYELENGGARA SARANA PELAKSANAAN TRANSAKSI DI PASAR UANG DAN PASAR VALUTA ASING",
                "UraianKBLI": "Kelompok ini mencakup kegiatan badan usaha yang menyediakan teknologi dan menyelenggarakan sarana untuk melaksanakan transaksi di pasar uang dan pasar valuta asing terhadap rupiah. Kelompok ini mencakup antara lain: perusahaan pialang pasar uang dan pasar valuta asing; dan penyedia electronic trading platform di pasar uang dan pasar valuta asing."
            },
            {
                "KBLI": "66413",
                "JudulKBLI": "PENYELENGGARA PENUNJANG SISTEM PEMBAYARAN",
                "UraianKBLI": "Kelompok ini mencakup kegiatan yang berkaitan dengan penyelenggaraan kegiatan yang mendukung pelaksanaan kegiatan Penyedia Jasa Pembayaran (PJP) dan\/atau Penyelenggara Infrastruktur Sistem Pembayaran (PIP) dalam melakukan pemrosesan transaksi pembayaran. Contoh pencetakan kartu, personalisasi pembayaran, penyediaan pusat data dan\/atau pusat pemulihan bencana, penyediaan terminal, penyediaan fitur keamanan instrumen pembayaran dan\/atau transaksi pembayaran, penyediaan teknologi pendukung transaksi nirkontak (contactless), penyediaan penerusan (routing) data pendukung pemrosesan transaksi pembayaran."
            },
            {
                "KBLI": "71204",
                "JudulKBLI": "PENELITIAN DAN PENGEMBANGAN BIOTEKNOLOGI",
                "UraianKBLI": "Kelompok ini mencakup usaha penelitian dan pengembangan yang dilakukan secara teratur (sistematik), yang diselenggarakan oleh swasta, berkaitan dengan bioteknologi."
            },
            {
                "KBLI": "72102",
                "JudulKBLI": "PENELITIAN DAN PENGEMBANGAN TEKNOLOGI DAN REKAYASA",
                "UraianKBLI": "Kelompok ini mencakup usaha penelitian dan pengembangan yang dilakukan secara teratur (sistematik), yang diselenggarakan oleh swasta, berkaitan dengan teknologi dan rekayasa (engineering). Kegiatan yang tercakup dalam kelompok ini seperti penelitian dan pengembangan ilmu teknik dan teknologi, serta antar cabang ilmu pengetahuan terutama ilmu pengetahuan alam dan teknik."
            },
            {
                "KBLI": "72107",
                "JudulKBLI": "PENELITIAN DAN PENGEMBANGAN KETENAGANUKLIRAN",
                "UraianKBLI": "Kelompok ini mencakup penelitian dan pengembangan yang dilakukan secara teratur (sistematis) yang berkaitan dengan penggunaan teknologi nuklir."
            },
            {
                "KBLI": "72109",
                "JudulKBLI": "PENELITIAN DAN PENGEMBANGAN ILMU PENGETAHUAN ALAM DAN TEKNOLOGI REKAYASA LAINNYA",
                "UraianKBLI": "Kelompok ini mencakup usaha penelitian dan pengembangan yang dilakukan secara teratur (sistematik), yang diselenggarakan oleh swasta, berkaitan dengan teknologi dan rekayasa (engineering) lainnya."
            },
            {
                "KBLI": "74115",
                "JudulKBLI": "AKTIVITAS DESAIN ALAT KOMUNIKASI DAN ELEKTRONIKA",
                "UraianKBLI": "Kelompok ini mencakup kegiatan aktivitas desain industri untuk menghasilkan produk dalam industri alat komunikasi dan elektronika Kelompok ini mencakup: desain untuk produk teknologi informasi, alat komunikasi dan kendali; desain untuk produk perekam suara atau gambar; desain untuk produk penyimpan data; desain untuk produk pembangkit daya dan penggerak motor; desain untuk produk penyimpan daya dan penguat daya; desain untuk produk distribusi dan kontrol listrik; desain untuk produk mesin pendingin, mesin cuci, mesin pengering dan pembersih."
            },
            {
                "KBLI": "74142",
                "JudulKBLI": "AKTIVITAS DESAIN KONTEN GAME",
                "UraianKBLI": "Kelompok ini mencakup kegiatan perencanaan konten kreatif game antara lain: desain logika mekanik permainan; desain cerita; desain artistic seperti desain visual karakter, desain user interface, desain level dan lain-lain; desain teknis terkait teknologi yang digunakan; pembuatan dokumen desain; riset dan pengembangan; dan aktivitas penunjang lainnya. Kegiatan produksi alat permainan masuk dalam kelompok 32401 dan pengembangan video game 62011."
            },
            {
                "KBLI": "77322",
                "JudulKBLI": "AKTIVITAS PENYEWAAN DAN SEWA GUNA TANPA HAK OPSI ALAT ALAT BANTU TEKNOLOGI DIGITAL",
                "UraianKBLI": "Kelompok ini mencakup kegiatan penyewaan dan sewa guna usaha tanpa hak opsi (operational leasing) mesin, peralatan dan barang kebutuhan teknologi digital yang mencakup sewa render farm, sewa motion capture, sewa 3D scanner, dan kebutuhan penunjang lain yang terkait aktivitas 5911 dan 5912. Sewa lisensi software dicakup dalam 58200."
            },
            {
                "KBLI": "78412",
                "JudulKBLI": "PELATIHAN KERJA TEKNOLOGI INFORMASI DAN KOMUNIKASI PEMERINTAH",
                "UraianKBLI": "Kelompok ini mencakup kegiatan pelatihan kerja yang bertujuan untuk menambah ketrampilan\/keahlian dalam bidang networking, cloud computing, website, technical support, computer engineering, programming, multimedia, database, system analyst, graphic design, office, animasi, artificial intelligence, IT governance, public relation, public speaking, IT software solution for business, dan lainnya yang diselenggarakan oleh pemerintah."
            },
            {
                "KBLI": "78422",
                "JudulKBLI": "PELATIHAN KERJA TEKNOLOGI INFORMASI DAN KOMUNIKASI SWASTA",
                "UraianKBLI": "Kelompok ini mencakup kegiatan pelatihan kerja yang bertujuan untuk menambah ketrampilan\/keahlian dalam bidang networking, technical support, computer engineering, programming, multimedia, database, system analyst, graphic design, office tools, animasi, artificial intelligence, IT governance, public relation, public speaking, dan lainnya yang diselenggarakan oleh swasta."
            },
            {
                "KBLI": "78432",
                "JudulKBLI": "PELATIHAN KERJA TEKNOLOGI INFORMASI DAN KOMUNIKASI PERUSAHAAN",
                "UraianKBLI": "Kelompok ini mencakup kegiatan pelatihan kerja yang bertujuan untuk menambah ketrampilan\/keahlian dalam bidang networking, technical support, computer engineering, programming, multimedia, database, system analyst, graphic design, office tools, animasi, artificial intelligence, IT governance, public relation, public speaking, dan lainnya yang diselenggarakan oleh perusahaan."
            },
            {
                "KBLI": "85311",
                "JudulKBLI": "PENDIDIKAN TINGGI AKADEMIK PEMERINTAH",
                "UraianKBLI": "Kelompok ini mencakup pendidikan yang memberikan tekanan pada pembentukan keahlian akademik di sekolah tinggi\/institut\/universitas, untuk mengenal penelitian dalam suatu bidang ilmu pengetahuan dan teknologi yang dikelola oleh pemerintah, meliputi jenis pendidikan akademik program sarjana, program magister dan program doktor."
            },
            {
                "KBLI": "85312",
                "JudulKBLI": "PENDIDIKAN TINGGI VOKASI DAN PROFESI PEMERINTAH",
                "UraianKBLI": "Kelompok ini mencakup pendidikan yang memberikan tekanan pada pembentukan keahlian profesional, yaitu keahlian yang menekankan pada ketrampilan dan penerapan suatu bidang ilmu pengetahuan dan teknologi dalam pekerjaan, meliputi jenis pendidikan vokasi program diploma satu, diploma dua, diploma tiga, dan diploma empat atau sarjana terapan, program magister terapan, program doktor terapan, serta jenis pendidikan profesi seperti program profesi dan program spesialis yang dikelola oleh pemerintah."
            },
            {
                "KBLI": "85492",
                "JudulKBLI": "JASA PENDIDIKAN KOMPUTER (TEKNOLOGI INFORMASI DAN KOMUNIKASI) SWASTA",
                "UraianKBLI": "Kelompok ini mencakup kegiatan pendidikan yang bersifat kursus dengan tujuan untuk menambah ketrampilan\/keahlian dalam bidang komputer dan teknologi informasi dan komunikasi yang diselenggarakan oleh swasta."
            },
            {
                "KBLI": "94122",
                "JudulKBLI": "AKTIVITAS ORGANISASI ILMU PENGETAHUAN ALAM DAN TEKNOLOGI",
                "UraianKBLI": "Kelompok ini mencakup kegiatan organisasi profesional yang bergerak di bidang ilmu pengetahuan alam dan teknologi, seperti Perhimpunan Insinyur Indonesia (PII) dan Ikatan Surveyor Indonesia (ISI)."
            }
        ];
        setKbliList(data);
    }, []);

    const handleKbliSelect = (e) => {
        setSelectedKbli(e.target.value);
    };

    const addKbli = () => {
        const kbli = kbliList.find((item) => item.KBLI === selectedKbli);
        if (kbli && !addedKbli.some((item) => item.KBLI === kbli.KBLI)) {
            setAddedKbli((prev) => [...prev, kbli]);
            setSelectedKbli(""); // Reset dropdown selection
        }
    };
    const [penghadapList, setPenghadapList] = useState([]);
    const [selectedPenghadap, setSelectedPenghadap] = useState('');
    const [addedPenghadap, setAddedPenghadap] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        API.ktp.getListKtp()
            .then(data => setPenghadapList(data.data || []))
            .catch(err => console.error('Error fetching document list:', err))
    }, []);

    const handleAktaTypeChange = (e) => {
        setAktaType(e.target.value);
        if (e.target.value !== 'Akta Pendirian PT') {
            setFormData({
                namaNotaris: 'Jonathan Hermawan, Sarjana Hukum, Magister Kenotariatan',
                lokasiNotaris: 'Kabupaten Tangerang',
                namaPT: '',
                nomorPT: '',
                alamatPT: '',
                tanggal: getCurrentDate(),
                jam: formattedTime,
                modalDasar: '',
                lembarSaham: '',
                modalSetorPT: '',
            });
            setAddedPenghadap([]);
            setErrors({});
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: ['modalDasar', 'modalSetorPT', 'lembarSaham'].includes(name)
                ? parseCurrency(value) // Parse currency for these fields
                : value,
        }));
    };

    const handlePenghadapSelect = (e) => {
        setSelectedPenghadap(e.target.value);
    };

    const addPenghadap = () => {
        const penghadap = penghadapList.find((p) => p.id.toString() === selectedPenghadap);
        if (penghadap && !addedPenghadap.some((p) => p.id === penghadap.id)) {
            setAddedPenghadap((prev) => [
                ...prev,
                {
                    ...penghadap,
                    jabatan: '',
                    pemegangSaham: 'Tidak',
                    modalDisetor: '',
                },
            ]);
            setSelectedPenghadap(''); // Reset dropdown selection
        } else {
            console.warn('Penghadap already added or not selected!');
        }
    };

    const updatePenghadapDetails = (id, field, value) => {
        setAddedPenghadap((prev) =>
            prev.map((peng) => (peng.id === id ? { ...peng, [field]: value } : peng))
        );
    };
    const capitalizeEachWord = (str) => {
        return str
            .split(" ") // Split the string into words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(" "); // Join the words back into a single string
    };
    const generateMarkdown = () => {
        let markdown = `<div style="text-align: center;"><strong>AKTA PENDIRIAN PERSEROAN TERBATAS</strong></div>
        <div style="text-align: center;"><strong>PT. ${formData.namaPT ? `<span style="background-color: #85db85">${formData.namaPT}</span>` : `<span style="background-color: orange">[Nama PT]</span>`}</strong></div>
        <div style="text-align: center;"><strong>Nomor: ${formData.nomorPT ? `<span style="background-color: #85db85">${formData.nomorPT}</span>` : `<span style="background-color: orange">[Nomor PT]</span>`}</strong></div>
        <ul>
            <li>-Pada hari ini,${getIndonesianDayName(formData.tanggal) ? `<span style="background-color: #85db85">${getIndonesianDayName(formData.tanggal)}</span>` : `<span style="background-color: orange">[Hari]</span>`}, ${dateToTerbilang(formData.tanggal) ? `<span style="background-color: #85db85">${dateToTerbilang(formData.tanggal)}</span>` : `<span style="background-color: orange">[Tanggal Terbilang]</span>`} (${formData.tanggal ? `<span style="background-color: #85db85">${formData.tanggal}</span>` : `<span style="background-color: orange">[Tanggal]</span>`}),${timeToTerbilang(formData.jam) ? `<span style="background-color: #85db85">${timeToTerbilang(formData.jam)}</span>` : `<span style="background-color: orange">[Terbilang Jam]</span>`} (${formData.jam ? `<span style="background-color: #85db85">${formData.jam}</span>` : `<span style="background-color: orange">[Jam]</span>`}) Waktu Indonesia Barat.</li>
            <li>-Hadir di hadapan saya, ${formData.namaNotaris ? `<span style="background-color: #85db85">${formData.namaNotaris}</span>` : `<span style="background-color: orange">[Nama Notaris]</span>`}, Notaris berkedudukan di ${formData.lokasiNotaris ? `<span style="background-color: #85db85">${formData.lokasiNotaris}</span>` : `<span style="background-color: orange">[Lokasi Notaris]</span>`}, dengan dihadiri saksi-saksi yang saya kenal, Notaris, kenal dan yang nama-namanya akan disebutkan pada bagian akhir akta ini:</li>
        </ul>`; 

        markdown += `<ul>`
        addedPenghadap.length === 0 ? markdown += `<li><span style="background-color: orange">Penghadap</span></li>`
        : addedPenghadap.forEach((peng, index) => {
            markdown += `<li><span style="background-color: #85db85"><strong>${index + 1}. Tuan ${peng.name}</strong>, lahir di ${peng.birth_place} pada tanggal ${peng.birth_date}, Warga Negara Indonesia, ${peng.job}, bertempat tinggal di ${peng.address}, Rukun Tetangga ${peng.rtrw?.split("/")[0]}, Rukun Warga ${peng.rtrw?.split("/")[1]}, Kelurahan ${peng.urban}, Kecamatan ${peng.subdistrict}, ${peng.city},${peng.province}, pemegang Kartu Tanda Penduduk Nomor Induk Kependudukan ${peng.identifier}.</span></li>\n`;
        });
        markdown += `</ul>`;
        markdown += ` <div style="text-align: center;"><strong>PASAL 1</strong></div>
  <div style="text-align: center;"><strong>NAMA DAN TEMPAT KEDUDUKAN</strong></div>
  <ul>
    <li>1. Perseroan terbatas ini bernama PT. ''${formData.namaPT ? `<span style="background-color: #85db85">${formData.namaPT}</span>` : `<span style="background-color: orange">[Nama PT]</span>`}'' (selanjutnya cukup disingkat dengan ''Perseroan''), berkedudukan di ${formData.alamatPT ? `<span style="background-color: #85db85">${formData.alamatPT}</span>` : `<span style="background-color: orange">[LOKASI]</span>`}.</li>
    <li>2. Perseroan dapat membuka kantor cabang atau kantor perwakilan, baik di dalam maupun di luar wilayah Republik Indonesia sebagaimana ditetapkan oleh Direksi.</li>
  </ul>`;

        markdown += `<div style="text-align: center;"><strong>JANGKA WAKTU BERDIRINYA PERSEROAN</strong></div>
        <div style="text-align: center;"><strong>PASAL 2</strong></div>
        <ul><li>-Perseroan didirikan untuk jangka waktu tidak terbatas.</li></ul>
`;

        markdown += `<div style="text-align: center;"><strong>MAKSUD DAN TUJUAN SERTA KEGIATAN USAHA</strong></div>
        <div style="text-align: center;"><strong>PASAL 3</strong></div>
        <ul>
        <li>1. Maksud dan tujuan Perseroan ialah berusaha dalam bidang:</li>`
        

        addedKbli.length === 0 ? markdown += `<li><span style="background-color: orange">KBLI</span></li>`
        : addedKbli.forEach((kbli, index) => {
            const letterIndex = String.fromCharCode(97 + index);
            markdown += `\n<li><span style="background-color: #85db85">${letterIndex}. ${capitalizeEachWord(kbli.JudulKBLI)}.</span></li>`;
        });



        markdown += `\n<li>2. Untuk mencapai maksud dan tujuan tersebut di atas Perseroan dapat melaksanakan kegiatan usaha sebagai berikut:</li>`;
        addedKbli.length === 0 ? markdown += `<li><span style="background-color: orange">Uraian KBLI</span></li>`
        : addedKbli.forEach((kbli, index) => {
            const letterIndex = String.fromCharCode(97 + index);
            markdown += `\n<li><span style="background-color: #85db85">${letterIndex}. ${(kbli.UraianKBLI)}.</span></li>`;
        });
        markdown += `</ul>`
        markdown += `\n<div style="text-align: center;"><strong>MODAL</strong></div>
        <div style="text-align: center;"><strong>PASAL 4</strong></div>
        <ul>
        <li>1. Modal dasar Perseroan berjumlah Rp.${formatCurrency(formData.modalDasar) ? `<span style="background-color: #85db85">${formatCurrency(formData.modalDasar)}</span>` : `<span style="background-color: orange">[Modal Dasar PT]</span>`}, terbagi atas ${formatCurrency(formData.lembarSaham) ? `<span style="background-color: #85db85">${formatCurrency(formData.lembarSaham)}</span>` : `<span style="background-color: orange">[Jumlah Lembar Saham PT]</span>`} saham, masing-masing saham bernilai nominal Rp.${formatCurrency(formData.modalDasar / formData.lembarSaham) ? `<span style="background-color: #85db85">${formatCurrency(formData.modalDasar / formData.lembarSaham)}</span>` : `<span style="background-color: orange">[Rp. per Saham]</span>`}.</li>
        <li>2. Dari modal dasar tersebut telah ditempatkan dan disetor sebanyak ${formData.modalSetorPT / (formData.modalDasar / formData.lembarSaham) ? `<span style="background-color: #85db85">${formData.modalSetorPT / (formData.modalDasar / formData.lembarSaham)}</span>` : `<span style="background-color: orange">[Saham Disetor]</span>`} saham dengan nilai nominal seluruhnya sebesar Rp.${formatCurrency(formData.modalSetorPT) ? `<span style="background-color: #85db85">${formatCurrency(formData.modalSetorPT)}</span>` : `<span style="background-color: orange">[Modal Setor PT]</span>`}, oleh para pendiri yang telah mengambil bagian saham dan rincian serta nilai nominal saham yang disebutkan pada bagian akhir akta sebelum penutup akta ini.</li>
        <li>3. Saham yang masih dalam simpanan akan dikeluarkan oleh Perseroan menurut keperluan modal Perseroan, dengan persetujuan Rapat Umum Pemegang Saham. Para pemegang saham yang namanya tercatat dalam Daftar Pemegang Saham mempunyai hak terlebih dahulu untuk mengambil bagian atas saham yang hendak dikeluarkan dalam jangka waktu 14 (empat belas) hari sejak tanggal penawaran dilakukan dan setiap pemegang saham berhak mengambil bagian seimbang dengan jumlah saham yang mereka miliki (proporsional) baik atas jumlah saham yang akan dikeluarkan maupun atas jumlah sisa saham yang tidak diambil oleh pemegang saham lainnya. Apabila jangka waktu penawaran 14 (empat belas) hari tersebut, telah lewat dan ternyata masih ada sisa saham yang belum diambil bagian, maka Direksi berhak menawarkan sisa saham tersebut kepada pihak ketiga.</li>
`;
        markdown += `</ul>`;

        markdown += `<div style="text-align: center;"><strong>SAHAM</strong></div>
        <div style="text-align: center;"><strong>PASAL 5</strong></div>
        <ul>
        <li>1. Semua saham yang dikeluarkan oleh Perseroan adalah Saham atas nama.</li>
<li>2. Yang boleh memiliki dan mempergunakan hak atas saham adalah Warga Negara Indonesia dan/atau badan hukum Indonesia.</li>
<li>3. Bukti pemilikan saham dapat berupa surat saham.</li>
<li>4. Dalam hal Perseroan tidak menerbitkan surat saham, pemilikan saham dapat dibuktikan dengan surat keterangan atau catatan yang dikeluarkan oleh Perseroan.</li>
<li>5. Dalam hal dikeluarkan surat saham, maka untuk setiap saham diberi sehelai surat saham.</li>
<li>6. Surat kolektif saham dapat dikeluarkan sebagai bukti pemilikan 2 (dua) saham atau lebih yang dimiliki oleh seorang pemegang saham.</li>
<li>7. Pada surat saham harus dicantumkan sekurang-kurangnya:</li>`
        markdown += `
   <li>a. nama dan alamat pemegang saham.</li>
   <li>b. nomor surat saham.</li>
   <li>c. nilai nominal saham.</li>
   <li>d. tanggal pengeluaran surat saham.</li>
<li>8. Pada surat kolektif saham harus dicantumkan sekurang-kurangnya:</li>`;
        markdown += `
   <li>a. nama dan alamat pemegang saham.</li>
   <li>b. nomor surat kolektif saham.</li>
   <li>c. nomor surat saham dan jumlah saham.</li>
   <li>d. nilai nominal saham.</li>
   <li>e. tanggal pengeluaran surat kolektif saham.</li>
<li>9. Surat saham dan surat kolektif saham harus ditandatangani oleh Direksi dengan persetujuan Dewan Komisaris</li></ul>.`;

        markdown += `
        <div style="text-align: center;"><strong>PENGGANTI SURAT SAHAM</strong></div>
        <div style="text-align: center;"><strong>PASAL 6</strong></div>
        <ul>
        <li>1. Dalam hal surat saham rusak atau tidak dapat dipakai, atas permintaan mereka yang berkepentingan, Direksi mengeluarkan surat saham pengganti, setelah surat saham yang rusak atau tidak dapat dipakai tersebut diserahkan kembali kepada Direksi.</li>
<li>2. Surat saham sebagaimana dimaksud pada ayat 1 harus dimusnahkan dan dibuat berita acara oleh Direksi untuk dilaporkan dalam Rapat Umum Pemegang Saham berikutnya.</li>
<li>3. Dalam hal surat saham hilang, atas permintaan mereka yang berkepentingan, Direksi mengeluarkan surat saham pengganti setelah menurut pendapat Direksi kehilangan tersebut cukup dibuktikan dan disertai jaminan yang dipandang perlu oleh Direksi untuk setiap peristiwa yang khusus.</li>
<li>4. Setelah surat saham pengganti dikeluarkan, surat saham yang dinyatakan hilang tersebut, tidak berlaku lagi terhadap Perseroan.</li>
<li>5. Semua biaya yang berhubungan dengan pengeluaran surat saham pengganti, ditanggung oleh pemegang saham yang berkepentingan.</li>
<li>6. Ketentuan sebagaimana dimaksud pada ayat 1, ayat 2, ayat 3, ayat 4 dan ayat 5 mutatis-mutandis berlaku bagi pengeluaran surat kolektif saham pengganti.</li>`;
        markdown += `</ul>
        <div style="text-align: center;"><strong>PEMINDAHAN HAK ATAS SAHAM</strong></div>
        <div style="text-align: center;"><strong>PASAL 7</strong></div>
        <ul>
        <li>1. Pemindahan hak atas saham, harus berdasarkan akta pemindahan hak yang ditandatangani oleh yang memindahkan dan yang menerima pemindahan atau kuasanya yang sah.</li>
<li>2. Pemegang saham yang hendak memindahkan hak atas saham harus menawarkan terlebih dahulu kepada pemegang saham lain dengan menyebutkan harga serta persyaratan penjualan dan memberitahukan kepada Direksi secara tertulis tentang penawaran tersebut.</li>
<li>3. Pemindahan hak atas saham harus dengan persetujuan terlebih dahulu dari Rapat Umum Pemegang Saham.</li>
<li>4. Pemindahan hak atas saham harus mendapat persetujuan dari instansi yang berwenang jika peraturan perundang-undangan mensyaratkan hal tersebut.</li>
<li>5. Mulai hari pemanggilan Rapat Umum Pemegang Saham sampai dengan hari dilaksanakan Rapat Umum Pemegang Saham pemindahan hak atas saham tidak diperkenankan.</li>
<li>6. Apabila karena warisan, perkawinan atau sebab lain saham tidak lagi menjadi milik warga negara Indonesia atau badan hukum Indonesia, maka dalam jangka waktu 1 (satu) tahun orang atau badan hukum yang bersangkutan wajib memindahkan hak atas sahamnya kepada warga negara Indonesia atau badan hukum Indonesia, sesuai ketentuan Anggaran Dasar.</li>`;
        markdown += `</ul>
        <div style="text-align: center;"><strong>RAPAT UMUM PEMEGANG SAHAM</strong></div>
        <div style="text-align: center;"><strong>PASAL 8</strong></div>
        <ul>
        <li>1. Rapat Umum Pemegang Saham yang selanjutnya disebut RUPS adalah:</li>
        <li>a. RUPS Tahunan.</li>
        <li>b. RUPS lainnya, yang dalam Anggaran Dasar ini disebut juga RUPS Luar Biasa.</li>
        <li>2. Istilah RUPS dalam Anggaran Dasar ini berarti keduanya, yaitu: RUPS Tahunan dan RUPS Luar Biasa, kecuali dengan tegas ditentukan lain.</li>
        <li>3. Dalam RUPS Tahunan:</li>
            <li>a. Direksi menyampaikan laporan tahunan yang telah ditelaah oleh Dewan Komisaris untuk mendapat persetujuan RUPS, dan laporan keuangan untuk mendapat pengesahan RUPS.</li>
            <li>b. Ditentukan penggunaan laba, dalam hal Perseroan mempunyai saldo laba yang positif.</li>
            <li>c. Diputuskan mata acara lainnya dari RUPS yang telah diajukan sebagaimana mestinya dengan memperhatikan ketentuan Anggaran Dasar.</li>
        <li>4. Persetujuan laporan tahunan dan pengesahan laporan keuangan oleh RUPS Tahunan berarti memberikan pelunasan dan pembebasan tanggung jawab sepenuhnya kepada anggota Direksi atas pengurusan dan Dewan Komisaris atas pengawasan yang telah dijalankan selama tahun buku yang lalu, sejauh tindakan tersebut tercermin dalam laporan tahunan dan laporan keuangan.</li>
        <li>5. RUPS Luar Biasa dapat diselenggarakan sewaktu-waktu berdasarkan kebutuhan untuk membicarakan dan memutuskan mata acara rapat, kecuali mata acara rapat yang dimaksud pada ayat 3 huruf a dan huruf b, dengan memperhatikan peraturan perundang-undangan dan Anggaran Dasar.</li>
        </ul>`;

        markdown += `<div style="text-align: center;"><strong>TEMPAT, PEMANGGILAN DAN PIMPINAN RUPS</strong></div>
        <div style="text-align: center;"><strong>PASAL 9</strong></div>
  
  <ul>
    <li>1. RUPS diadakan di tempat kedudukan Perseroan.</li>
    <li>2. RUPS diselenggarakan dengan melakukan pemanggilan terlebih dahulu kepada para pemegang saham dengan surat tercatat dan/atau dengan iklan dalam surat kabar.</li>
    <li>3. Pemanggilan dilakukan paling lambat 14 (empat belas) hari sebelum tanggal RUPS diadakan dengan tidak memperhitungkan tanggal panggilan dan tanggal RUPS diadakan.</li>
    <li>4. Pemanggilan RUPS tidak diperlukan dalam hal semua pemegang saham hadir dan semua menyetujui agenda rapat serta keputusan disetujui dengan suara bulat.</li>
    <li>5. RUPS dipimpin oleh Direktur Utama.</li>
    <li>6. Dalam hal Direktur Utama tidak ada atau berhalangan karena sebab apapun yang tidak perlu dibuktikan kepada pihak ketiga, RUPS dipimpin oleh salah seorang anggota Direksi.</li>
    <li>7. Dalam hal semua Direktur tidak hadir atau berhalangan karena sebab apapun yang tidak perlu dibuktikan kepada pihak ketiga, RUPS dipimpin oleh salah seorang anggota Dewan Komisaris.</li>
    <li>8. Dalam hal semua anggota Dewan Komisaris tidak hadir atau berhalangan karena sebab apapun yang tidak perlu dibuktikan kepada pihak ketiga, RUPS dipimpin oleh seorang yang dipilih oleh dan diantara mereka yang hadir dalam rapat.</li>
  </ul>`

//        //PASAL 9
//        markdown += `<div style="text-align: center;"><strong>TEMPAT, PEMANGGILAN DAN PIMPINAN RUPS</strong></div>\n`;
//        markdown += `<div style="text-align: center;"><strong>PASAL 9</strong></div>`;

//        markdown += `<ol >`


//        markdown += `
//        <li>RUPS diadakan di tempat kedudukan Perseroan.
//2. RUPS diselenggarakan dengan melakukan pemanggilan terlebih dahulu kepada para pemegang saham dengan surat tercatat dan/atau dengan iklan dalam surat kabar.
//3. Pemanggilan dilakukan paling lambat 14 (empat belas) hari sebelum tanggal RUPS diadakan dengan tidak memperhitungkan tanggal panggilan dan tanggal RUPS diadakan.
//4. Pemanggilan RUPS tidak diperlukan dalam hal semua pemegang saham hadir dan semua menyetujui agenda rapat dan keputusan disetujui dengan suara bulat.
//5. RUPS dipimpin oleh Direktur Utama.
//6. Dalam hal Direktur Utama tidak ada atau berhalangan karena sebab apapun yang tidak perlu dibuktikan kepada pihak ketiga, RUPS dipimpin oleh salah seorang anggota Direksi.
//7. Dalam hal semua Direktur tidak hadir atau berhalangan karena sebab apapun yang tidak perlu dibuktikan kepada pihak ketiga, RUPS dipimpin oleh salah seorang anggota Dewan Komisaris.
//8. Dalam hal semua anggota Dewan Komisaris tidak hadir atau berhalangan karena sebab apapun yang tidak perlu dibuktikan kepada pihak ketiga, RUPS dipimpin oleh seorang yang dipilih oleh dan diantara mereka yang hadir dalam rapat.`;

        //PASAL 10
        markdown += `
        <div style="text-align: center;"><strong>KUORUM, HAK SUARA, DAN KEPUTUSAN RUPS</strong></div>
        <div style="text-align: center;"><strong>PASAL 10</strong></div>
        <ul>
        <li>1. RUPS dapat dilangsungkan apabila kuorum kehadiran sebagaimana disyaratkan dalam Undang-Undang tentang Perseroan Terbatas telah dipenuhi.</li>
        <li>2. Pemungutan suara mengenai diri orang dilakukan dengan surat tertutup yang tidak ditandatangani dan mengenai hal lain secara lisan, kecuali apabila ketua RUPS menentukan lain tanpa ada keberatan dari pemegang saham yang hadir dalam RUPS.</li>
        <li>3. Suara blanko atau suara yang tidak sah dianggap tidak ada dan tidak dihitung dalam menentukan jumlah suara yang dikeluarkan dalam RUPS.</li>
        <li>4. RUPS dapat mengambil keputusan sebagaimana ditentukan dalam Undang-Undang.</li>
        </ul>`;


        markdown += `
        <div style="text-align: center;"><strong>DIREKSI</strong></div>
        <div style="text-align: center;"><strong>PASAL 11</strong></div>
        <ul>
        <li>1. Perseroan diurus dan dipimpin oleh Direksi yang terdiri dari seorang anggota Direksi atau lebih.</li>
        <li>2. Jika diangkat lebih dari seorang anggota Direksi, maka seorang diantaranya dapat diangkat sebagai Direktur Utama.</li>
        <li>3. Yang boleh diangkat sebagai anggota Direksi hanya warga negara Indonesia yang memenuhi persyaratan yang ditentukan peraturan perundang-undangan yang berlaku.</li>
        <li>4. Anggota Direksi diangkat oleh Rapat Umum Pemegang Saham, untuk jangka waktu 5 tahun dengan tidak mengurangi hak Rapat Umum Pemegang Saham untuk memberhentikannya sewaktu-waktu.</li>
        <li>5. Jika oleh sebab apapun jabatan seorang atau lebih atau semua anggota Direksi lowong, maka dalam jangka waktu 30 (tiga puluh) hari sejak terjadi lowongan harus diselenggarakan Rapat Umum Pemegang Saham, untuk mengisi lowongan itu dengan memperhatikan ketentuan peraturan perundang-undangan dan Anggaran Dasar.</li>
        <li>6. Jika oleh sebab apapun semua jabatan anggota Direksi lowong, untuk sementara Perseroan diurus oleh anggota Dewan Komisaris yang ditunjuk oleh rapat Dewan Komisaris.</li>
        <li>7. Anggota Direksi berhak mengundurkan diri dari jabatannya dengan memberitahukan secara tertulis kepada Perseroan paling kurang 30 (tiga puluh) hari sebelum tanggal pengunduran dirinya.</li>
        <li>8. Jabatan anggota Direksi berakhir jika:</li>
        <li>Mengundurkan diri sesuai ketentuan ayat 7.</li>
        <li>Tidak lagi memenuhi persyaratan peraturan perundang-undangan.</li>
        <li>Meninggal dunia.</li>
        <li>Diberhentikan berdasarkan keputusan Rapat Umum Pemegang Saham.</li>
        </ul>`;

        markdown += `
        <div style="text-align: center;"><strong>TUGAS DAN WEWENANG DIREKSI</strong></div>
        <div style="text-align: center;"><strong>PASAL 12</strong></div>
        <ul>
        <li>1. Direksi berhak mewakili Perseroan di dalam dan di luar Pengadilan tentang segala hal dan dalam segala kejadian, mengikat Perseroan dengan pihak lain dan pihak lain dengan Perseroan, serta menjalankan segala tindakan, baik yang mengenai kepengurusan maupun kepemilikan, dengan pembatasan bahwa untuk:</li>
        <li>a. Meminjam atau meminjamkan uang atas nama Perseroan (tidak termasuk mengambil uang Perseroan di Bank).</li>
        <li>b. Mendirikan suatu usaha atau turut serta pada perusahaan lain baik di dalam maupun di luar negeri.</li>
        <li>- Harus dengan persetujuan Dewan Komisaris.</li>
        <li>2. a. Direktur Utama berhak dan berwenang bertindak untuk dan atas nama Direksi serta mewakili Perseroan.</li>
        <li>2. b.Dalam hal Direktur Utama tidak ada atau tidak hadir atau berhalangan karena sebab apapun yang tidak perlu dibuktikan kepada pihak ketiga, maka salah seorang anggota Direksi lainnya berhak dan berwenang bertindak untuk dan atas nama Direksi serta mewakili Perseroan.</li>
        <li>3. Dalam hal hanya ada seorang anggota Direksi, maka segala tugas dan wewenang yang diberikan kepada Direktur Utama atau anggota Direksi lainnya dalam Anggaran Dasar ini berlaku juga baginya.</li>
        </ul>`;

        markdown += `
        <div style="text-align: center;"><strong>RAPAT DIREKSI</strong></div>
        <div style="text-align: center;"><strong>PASAL 13</strong></div>
  <ul>
    <li>1. Penyelenggaraan Rapat Direksi dapat dilakukan setiap waktu:</li>
      <li>a. Apabila dipandang perlu oleh seorang atau lebih anggota Direksi.</li>
      <li>b. Atas permintaan tertulis dari seorang atau lebih anggota Dewan Komisaris.</li>
      <li>c. Atas permintaan tertulis dari 1 (satu) orang atau lebih pemegang saham yang bersama-sama mewakili 1/10 (satu per sepuluh) atau lebih dari jumlah seluruh saham dengan hak suara.</li>
    <li>2. Pemanggilan Rapat Direksi dilakukan oleh anggota Direksi yang berhak bertindak untuk dan atas nama Direksi menurut ketentuan Pasal 9 Anggaran Dasar ini.</li>
    <li>3. Pemanggilan Rapat Direksi dilakukan dengan surat tercatat yang disampaikan paling lambat 3 (tiga) hari sebelum rapat diadakan dengan tidak memperhitungkan tanggal panggilan dan tanggal rapat.</li>
    <li>4. Panggilan rapat itu harus mencantumkan acara, tanggal, waktu, dan tempat rapat.</li>
    <li>5. Rapat Direksi diadakan di tempat kedudukan Perseroan atau tempat kegiatan usaha Perseroan. Apabila semua anggota Direksi hadir atau diwakili, panggilan terlebih dahulu tersebut tidak disyaratkan dan Rapat Direksi dapat diadakan dimanapun juga dan berhak mengambil keputusan yang sah dan mengikat.</li>
    <li>6. Rapat Direksi dipimpin oleh Direktur Utama.</li>
    <li>7. Dalam hal Direktur Utama tidak dapat hadir atau berhalangan yang tidak perlu dibuktikan kepada pihak ketiga, Rapat Direksi dipimpin oleh seorang anggota Direksi yang dipilih oleh dan dari antara anggota Direksi yang hadir.</li>
    <li>8. Seorang anggota Direksi dapat diwakili dalam Rapat Direksi hanya oleh anggota Direksi lainnya berdasarkan surat kuasa.</li>
    <li>9. Rapat Direksi adalah sah dan berhak mengambil keputusan yang mengikat apabila lebih dari 1/2 (satu per dua) dari jumlah anggota Direksi hadir atau diwakili dalam rapat.</li>
    <li>10. Keputusan Rapat Direksi harus diambil berdasarkan musyawarah untuk mufakat. Apabila tidak tercapai, maka keputusan diambil dengan pemungutan suara berdasarkan suara setuju paling sedikit lebih dari 1/2 (satu per dua) jumlah suara yang dikeluarkan dalam rapat.</li>
    <li>11. Apabila suara yang setuju dan yang tidak setuju berimbang, ketua rapat yang akan menentukan.</li>
        <li>12. a. Setiap anggota Direksi yang hadir berhak mengeluarkan 1 (satu) suara dan tambahan 1 (satu) suara untuk setiap anggota Direksi lain yang diwakilinya.</li>
        <li>12. b. Pemungutan suara mengenai diri orang dilakukan dengan surat suara tertutup tanpa tanda tangan, sedangkan pemungutan suara mengenai hal-hal lain dilakukan secara lisan, kecuali ketua rapat menentukan lain tanpa ada keberatan dari yang hadir.</li>
        <li>12. c. Suara blanko dan suara yang tidak sah dianggap tidak dikeluarkan secara sah dan dianggap tidak ada serta tidak dihitung dalam menentukan jumlah suara yang dikeluarkan.</li>
    <li>13. Direksi dapat juga mengambil keputusan yang sah tanpa mengadakan Rapat Direksi, dengan ketentuan semua anggota Direksi telah diberitahu secara tertulis dan semua anggota Direksi memberikan persetujuan mengenai usul yang diajukan secara tertulis dengan menandatangani persetujuan tersebut.</li>
  </ul>`;

        markdown += `
        <div style="text-align: center;"><strong>DEWAN KOMISARIS</strong></div>
        <div style="text-align: center;"><strong>PASAL 14</strong></div>
  <ul>
    <li>1. Dewan Komisaris terdiri dari seorang atau lebih anggota Dewan Komisaris. Apabila diangkat lebih dari seorang anggota Dewan Komisaris, maka seorang diantaranya dapat diangkat sebagai Komisaris Utama.</li>
    <li>2. Yang boleh diangkat sebagai anggota Dewan Komisaris hanya warga negara Indonesia yang memenuhi persyaratan yang ditentukan peraturan perundang-undangan yang berlaku.</li>
    <li>3. Anggota Dewan Komisaris diangkat oleh Rapat Umum Pemegang Saham untuk jangka waktu 5 tahun dengan tidak mengurangi hak Rapat Umum Pemegang Saham untuk memberhentikan sewaktu-waktu.</li>
    <li>4. Jika oleh sebab apapun jabatan anggota Dewan Komisaris lowong, maka dalam jangka waktu 30 (tiga puluh) hari sejak terjadinya lowongan, harus diselenggarakan Rapat Umum Pemegang Saham untuk mengisi lowongan itu dengan memperhatikan ketentuan ayat 2 Pasal ini.</li>
    <li>5. Anggota Dewan Komisaris berhak mengundurkan diri dari jabatannya dengan memberitahukan secara tertulis mengenai maksud tersebut kepada Perseroan paling kurang 30 (tiga puluh) hari sebelum tanggal pengunduran dirinya.</li>
    <li>6. Anggota Dewan Komisaris dapat diberhentikan sewaktu-waktu berdasarkan Rapat Umum Pemegang Saham dengan menyebutkan alasannya.</li>
    <li>7. Keputusan untuk memberhentikan anggota Dewan Komisaris sebagaimana dimaksud pada ayat 6 diambil setelah yang bersangkutan diberi kesempatan untuk membela diri dalam Rapat Umum Pemegang Saham.</li>
    <li>8. Dalam hal keputusan untuk memberhentikan anggota Dewan Komisaris sebagaimana dimaksud pada ayat 7 dilakukan dengan keputusan di luar Rapat Umum Pemegang Saham sesuai dengan ketentuan sebagaimana dimaksud dalam Pasal 91 UUPT, anggota Dewan Komisaris yang bersangkutan diberitahu terlebih dahulu tentang rencana pemberhentian dan diberikan kesempatan untuk membela diri sebelum diambil keputusan pemberhentian.</li>
  </ul>`;


        markdown += `
        <div style="text-align: center;"><strong>TUGAS DAN WEWENANG DEWAN KOMISARIS</strong></div>
        <div style="text-align: center;"><strong>PASAL 15</strong></div>
  <ul>
    <li>1. Dewan Komisaris dalam rangka pengawasan dan pemberian nasihat kepada Direksi setiap waktu dalam jam kantor Perseroan berhak memasuki bangunan dan halaman atau tempat lain yang dipergunakan atau yang dikuasai oleh Perseroan dan berhak memeriksa semua pembukuan, surat, dan alat bukti lainnya, memeriksa dan mencocokkan keadaan uang kas dan lain-lain serta berhak untuk mengetahui segala tindakan yang telah dijalankan oleh Direksi.</li>
    <li>2. Dalam menjalankan tugas, Dewan Komisaris berhak memperoleh penjelasan dari Direksi atau setiap anggota Direksi tentang segala hal yang diperlukan oleh Dewan Komisaris.</li>
    <li>3. Dewan Komisaris diwajibkan mengurus Perseroan untuk sementara dalam hal seluruh anggota Direksi diberhentikan untuk sementara atau Perseroan tidak mempunyai seorangpun anggota Direksi. Dalam hal demikian, Dewan Komisaris berhak untuk memberikan kekuasaan sementara kepada seorang atau lebih di antara anggota Dewan Komisaris atas tanggungan Dewan Komisaris.</li>
    <li>4. Dalam hal hanya ada seorang anggota Dewan Komisaris, segala tugas dan wewenang yang diberikan kepada Komisaris Utama atau Dewan Komisaris dalam Anggaran Dasar ini berlaku pula baginya.</li>
  </ul>`;

        markdown += `
        <div style="text-align: center;"><strong>RAPAT DEWAN KOMISARIS</strong></div>
        <div style="text-align: center;"><strong>PASAL 16</strong></div>
  <ul>
    <li>1. Penyelenggaraan Rapat Dewan Komisaris dapat dilakukan setiap waktu apabila dipandang perlu:</li>
      <li>a. Oleh seorang atau lebih anggota Dewan Komisaris.</li>
      <li>b. Atas permintaan tertulis dari seorang atau lebih anggota Direksi.</li>
      <li>c. Atas permintaan tertulis dari 1 (satu) orang atau lebih pemegang saham yang bersama-sama mewakili 1/10 (satu per sepuluh) atau lebih dari jumlah seluruh saham dengan hak suara.</li>
    <li>2. Pemanggilan Rapat Dewan Komisaris dilakukan oleh anggota Dewan Komisaris yang berhak bertindak untuk dan atas nama Dewan Komisaris menurut ketentuan Pasal 9 Anggaran Dasar ini.</li>
    <li>3. Pemanggilan Rapat Dewan Komisaris dilakukan dengan Surat Tercatat yang disampaikan paling lambat 3 (tiga) hari sebelum rapat diadakan dengan tidak memperhitungkan tanggal panggilan dan tanggal rapat.</li>
    <li>4. Panggilan rapat itu harus mencantumkan acara, tanggal, waktu, dan tempat rapat.</li>
    <li>5. Rapat Dewan Komisaris diadakan di tempat kedudukan Perseroan atau tempat kegiatan usaha Perseroan. Apabila semua anggota Dewan Komisaris hadir atau diwakili, panggilan terlebih dahulu tersebut tidak disyaratkan dan Rapat Dewan Komisaris dapat diadakan dimanapun juga dan berhak mengambil keputusan yang sah dan mengikat.</li>
    <li>6. Rapat Dewan Komisaris dipimpin oleh Komisaris Utama.</li>
    <li>7. Dalam hal Komisaris Utama tidak dapat hadir atau berhalangan yang tidak perlu dibuktikan kepada pihak ketiga, Rapat Dewan Komisaris dipimpin oleh seorang anggota Dewan Komisaris yang dipilih oleh dan dari antara anggota Dewan Komisaris yang hadir.</li>
    <li>8. Seorang anggota Dewan Komisaris dapat diwakili dalam Rapat Dewan Komisaris hanya oleh anggota Dewan Komisaris lainnya berdasarkan surat kuasa.</li>
    <li>9. Rapat Dewan Komisaris adalah sah dan berhak mengambil keputusan yang mengikat apabila lebih dari 1/2 (satu per dua) dari jumlah anggota Dewan Komisaris hadir atau diwakili dalam rapat.</li>
    <li>10. Keputusan Rapat Dewan Komisaris harus diambil berdasarkan musyawarah untuk mufakat. Apabila tidak tercapai, maka keputusan diambil dengan pemungutan suara berdasarkan suara setuju paling sedikit lebih dari 1/2 (satu per dua) jumlah suara yang dikeluarkan dalam rapat.</li>
    <li>11. Apabila suara yang setuju dan yang tidak setuju berimbang, ketua rapat yang akan menentukan.</li>
        <li>12. a. Setiap anggota Dewan Komisaris yang hadir berhak mengeluarkan 1 (satu) suara dan tambahan 1 (satu) suara untuk setiap anggota Dewan Komisaris lain yang diwakilinya.</li>
        <li>12. b. Pemungutan suara mengenai diri orang dilakukan dengan surat suara tertutup tanpa tanda tangan, sedangkan pemungutan suara mengenai hal-hal lain dilakukan secara lisan, kecuali ketua rapat menentukan lain tanpa ada keberatan dari yang hadir.</li>
        <li>12. c. Suara blanko dan suara yang tidak sah dianggap tidak dikeluarkan secara sah dan dianggap tidak ada serta tidak dihitung dalam menentukan jumlah suara yang dikeluarkan.</li>
    <li>13. Dewan Komisaris dapat juga mengambil keputusan yang sah tanpa mengadakan Rapat Dewan Komisaris, dengan ketentuan semua anggota Dewan Komisaris telah diberitahu secara tertulis dan semua anggota Dewan Komisaris memberikan persetujuan mengenai usul yang diajukan secara tertulis dengan menandatangani persetujuan tersebut.</li>
  </ul>`;

        markdown += `
        <div style="text-align: center;"><strong>RENCANA KERJA, TAHUN BUKU DAN LAPORAN TAHUNAN</strong></div>
        <div style="text-align: center;"><strong>PASAL 17</strong></div>
  <ul>
    <li>1. Direksi menyampaikan rencana kerja yang memuat juga anggaran tahunan Perseroan kepada Dewan Komisaris untuk mendapat persetujuan, sebelum tahun buku dimulai.</li>
    <li>2. Rencana kerja sebagaimana dimaksud pada ayat 1 harus disampaikan paling lambat 30 (tiga puluh) hari sebelum dimulainya tahun buku yang akan datang.</li>
    <li>3. Tahun buku Perseroan berjalan dari tanggal 1 Januari sampai dengan tanggal 31 Desember. Pada setiap akhir bulan Desember, buku Perseroan ditutup.</li>
    <li>4. Direksi menyusun laporan tahunan dan menyediakannya di kantor Perseroan untuk dapat diperiksa oleh para pemegang saham terhitung sejak tanggal pemanggilan Rapat Umum Pemegang Saham Tahunan.</li>
  </ul>`;

        markdown += `
        <div style="text-align: center;"><strong>PENGGUNAAN LABA DAN PEMBAGIAN DIVIDEN</strong></div>
        <div style="text-align: center;"><strong>PASAL 18</strong></div>
  <ul>
    <li>1. Laba bersih Perseroan dalam suatu tahun buku seperti tercantum dalam neraca dan perhitungan laba rugi yang telah disahkan oleh Rapat Umum Pemegang Saham Tahunan dan merupakan saldo laba yang positif, dibagi menurut cara penggunaannya yang ditentukan oleh Rapat Umum Pemegang Saham tersebut.</li>
    <li>2. Jika perhitungan laba rugi pada suatu tahun buku menunjukkan kerugian yang tidak dapat ditutup dengan dana cadangan, maka kerugian itu tetap dicatat dan dimasukkan dalam perhitungan laba rugi. Dalam tahun buku selanjutnya, Perseroan dianggap tidak mendapat laba selama kerugian yang tercatat dan dimasukkan dalam perhitungan laba rugi itu belum sama sekali tertutup seluruhnya.</li>
    <li>3. Perseroan dapat membagikan dividen interim sebelum tahun buku Perseroan berakhir.</li>
  </ul>`;

        markdown += `
        <div style="text-align: center;"><strong>PENGGUNAAN CADANGAN</strong></div>
        <div style="text-align: center;"><strong>PASAL 19</strong></div>
  <ul>
    <li>1. Penyisihan laba bersih untuk cadangan sampai mencapai 20% (dua puluh persen) dari jumlah modal ditempatkan dan disetor hanya boleh dipergunakan untuk menutup kerugian yang tidak dipenuhi oleh cadangan lain.</li>
    <li>2. Jika jumlah cadangan telah melebihi 20% (dua puluh persen), Rapat Umum Pemegang Saham dapat memutuskan agar jumlah kelebihannya digunakan bagi keperluan Perseroan.</li>
    <li>3. Cadangan sebagaimana dimaksud pada ayat 1 yang belum dipergunakan untuk menutup kerugian dan kelebihan cadangan sebagaimana dimaksud pada ayat 2 yang penggunaannya belum ditentukan oleh Rapat Umum Pemegang Saham harus dikelola dengan cara yang tepat menurut pertimbangan Direksi setelah memperoleh persetujuan Dewan Komisaris dan memperhatikan peraturan perundang-undangan.</li>
  </ul>`;


        markdown += `
        <div style="text-align: center;"><strong>KETENTUAN PENUTUP</strong></div>
        <div style="text-align: center;"><strong>PASAL 20</strong></div>
    <ul>
    <li>1. Segala sesuatu yang tidak atau belum cukup diatur dalam Anggaran Dasar ini akan diputus dalam Rapat Umum Pemegang Saham.</li>
    <li>2. Modal ditempatkan sebagaimana dimaksud dalam Pasal 4 ayat 2 telah diambil bagian dan disetor penuh dengan uang tunai melalui kas Perseroan oleh para pendiri:</li>`


        addedPenghadap.length === 0 ? markdown += `<li><span style="background-color: orange">Pemegang Saham</span></li>`

        : addedPenghadap.forEach((peng, index) => {
            if (peng.pemegangSaham === "Ya") {
                const letterIndex = String.fromCharCode(97 + index);
                markdown += `\n<li><span style="background-color: #85db85"><strong>${letterIndex}. Tuan ${peng.name}</strong>, sebanyak <strong> ${formatCurrency(peng.modalDisetor / formData.lembarSaham)}</strong> saham, dengan nilai nominal seluruhnya sebesar <strong>Rp.${formatCurrency(peng.modalDisetor)}</strong></span></li>
                `;
            }
        });
        markdown += `<li>- Sehingga seluruhnya berjumlah <strong>${formatCurrency(formData.lembarSaham) ? `<span style="background-color: #85db85">${formatCurrency(formData.lembarSaham)}</span>` : `<span style="background-color: orange">[Jumlah Lembar Saham PT]</span>`}</strong> saham dengan nilai nominal seluruhnya sebesar <strong>Rp.${formatCurrency(formData.modalSetorPT) ? `<span style="background-color: #85db85">${formatCurrency(formData.modalSetorPT)}</span>` : `<span style="background-color: orange">[Modal Setor PT]</span>`}</strong>.</li>
        <li>3. Menyimpang dari ketentuan dalam Pasal 11 dan Pasal 14 Anggaran Dasar ini mengenai tata cara pengangkatan anggota Direksi dan Dewan Komisaris, maka untuk pertama kali telah diangkat sebagai:</li>
        <li><strong>DIREKSI:</strong></li>
    </ul>
    <ul>
    `

        let check_index = 0

        addedPenghadap.length === 0 ? markdown += `<li><span style="background-color: orange">Direksi</span></li>`

        : addedPenghadap.forEach((peng, index) => {
            if (peng.jabatan === "Presiden Direktur") {
                const letterIndex = String.fromCharCode(97 + check_index);
                markdown += `\n<li><span style="background-color: #85db85"><strong>${letterIndex}. Presiden Direktur</strong>          :<strong>Tuan ${peng.name}</strong>, lahir di <strong>${peng.birth_place}</strong> pada tanggal <strong>${peng.birth_date}</strong>, Warga Negara Indonesia, ${peng.job}, bertempat tinggal di <strong>${peng.address}</strong>, Rukun Tetangga ${peng.rtrw?.split("/")[0]}, Rukun Warga ${peng.rtrw?.split("/")[1]}, Kelurahan ${peng.urban}, Kecamatan ${peng.subdistrict}, ${peng.city},${peng.province}, pemegang Kartu Tanda Penduduk Nomor Induk Kependudukan ${peng.identifier}.</span></li>\n`;
                check_index += 1

            }
        });
        addedPenghadap.forEach((peng, index) => {
            if (peng.jabatan === "Direktur") {
                const letterIndex = String.fromCharCode(97 + check_index);
                markdown += `\n<li><span style="background-color: #85db85"><strong>${letterIndex}. Direktur</strong>          :<strong>Tuan ${peng.name}</strong>, lahir di <strong>${peng.birth_place}</strong> pada tanggal <strong>${peng.birth_date}</strong>, Warga Negara Indonesia, ${peng.job}, bertempat tinggal di <strong>${peng.address}</strong>, Rukun Tetangga ${peng.rtrw?.split("/")[0]}, Rukun Warga ${peng.rtrw?.split("/")[1]}, Kelurahan ${peng.urban}, Kecamatan ${peng.subdistrict}, ${peng.city},${peng.province}, pemegang Kartu Tanda Penduduk Nomor Induk Kependudukan ${peng.identifier}.</span></li>\n`;
                check_index += 1

            }
        });

        markdown += `<li><strong>DEWAN KOMISARIS:</strong></li>
        `
        let check_index_kom = 0
        addedPenghadap.length === 0 ? markdown += `<li><span style="background-color: orange">Dewan Komisaris</span></li>`
        : addedPenghadap.forEach((peng, index) => {
            if (peng.jabatan === "Presiden Komisaris") {
                const letterIndex = String.fromCharCode(97 + check_index_kom);
                markdown += `\n<li><span style="background-color: #85db85"><strong>${letterIndex}. Presiden Komisaris</strong>    :<strong>Tuan ${peng.name}</strong>, lahir di <strong>${peng.birth_place}</strong> pada tanggal <strong>${peng.birth_date}</strong>, Warga Negara Indonesia, ${peng.job}, bertempat tinggal di <strong>${peng.address}</strong>, Rukun Tetangga ${peng.rtrw?.split("/")[0]}, Rukun Warga ${peng.rtrw?.split("/")[1]}, Kelurahan ${peng.urban}, Kecamatan ${peng.subdistrict}, ${peng.city},${peng.province}, pemegang Kartu Tanda Penduduk Nomor Induk Kependudukan ${peng.identifier}.</span></li>\n`;
                check_index_kom +=1
            }
        });
        addedPenghadap.forEach((peng, index) => {
            if (peng.jabatan === "Komisaris") {
                const letterIndex = String.fromCharCode(97 + check_index_kom);
                markdown += `\n<li><span style="background-color: #85db85"><strong>${letterIndex}. Komisaris</strong>         :<strong>Tuan ${peng.name}</strong>, lahir di <strong>${peng.birth_place}</strong> pada tanggal <strong>${peng.birth_date}</strong>, Warga Negara Indonesia, ${peng.job}, bertempat tinggal di <strong>${peng.address}</strong>, Rukun Tetangga ${peng.rtrw?.split("/")[0]}, Rukun Warga ${peng.rtrw?.split("/")[1]}, Kelurahan ${peng.urban}, Kecamatan ${peng.subdistrict}, ${peng.city},${peng.province}, pemegang Kartu Tanda Penduduk Nomor Induk Kependudukan ${peng.identifier}.</span></li>\n`;
                check_index_kom += 1
            }
        });



        markdown += `</ul>`

            
        markdown+=`
      </ul>`
    ;


        console.log(markdown)
        return markdown;
    };

    const exportMarkdown = () => {
        const markdownContent = generateMarkdown();
        const blob = new Blob([markdownContent], { type: 'text/markdown;charset=utf-8' });
        saveAs(blob, 'output.md');
    };

    const exportToWord = async () => {
        const markdownContent = generateMarkdown();
        
        // API.akta.exportDocumentToWord(markdownContent)
        //     .then(response => {
        //         if (response.status === 200) {
        //             const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        //             saveAs(blob, 'PTDocument.docx');
        //         } else {
        //             console.error('Error processing Markdown on the backend.');
        //         }
        //     })

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/document/process-markdown`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyN2IzMjQ1LTA0OTMtNGNlOS05MmNhLWFkMWQ4ZDZkZTY2MyIsImlhdCI6MTczMzc0MTU4MSwiZXhwIjoxNzMzOTE0MzgxfQ.jtev3cQ6drs8_GbIIgDzX9clIDhqpmEOrYH-FBgU3O4'
            },
            body: JSON.stringify({ markdown: markdownContent }),
        });

        if (response.ok) {
            const blob = await response.blob();
            saveAs(blob, 'PTDocument.docx');
        } else {
            console.error('Error processing Markdown on the backend.');
        }
    };

    return (
        <Fragment>
            <Row>
                <Col md="6">
                    <Card>
                        <CardHeader>
                            <H5>Input Section</H5>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <label htmlFor="aktaType">Select Akta Type</label>
                                <Input
                                    type="select"
                                    id="aktaType"
                                    value={aktaType}
                                    onChange={handleAktaTypeChange}
                                >
                                    <option value="">Select an option</option>
                                    <option value="Akta Pendirian PT">Akta Pendirian PT</option>
                                </Input>
                            </FormGroup>
                            {aktaType === 'Akta Pendirian PT' && (
                                <>
                                    <FormGroup>
                                        <label htmlFor="namaNotaris">Nama Notaris</label>
                                        <Input
                                            type="text"
                                            id="namaNotaris"
                                            name="namaNotaris"
                                            className={`form-control ${errors.namaNotaris ? 'is-invalid' : ''}`}
                                            value={formData.namaNotaris}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="lokasiNotaris">Lokasi Notaris</label>
                                        <Input
                                            type="text"
                                            id="lokasiNotaris"
                                            name="lokasiNotaris"
                                            className={`form-control ${errors.lokasiNotaris ? 'is-invalid' : ''}`}
                                            value={formData.lokasiNotaris}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="Tanggal Hari ini">Tanggal Hari ini</label>
                                        <Input
                                            type="text"
                                            id="tanggal"
                                            name="tanggal"
                                            className={`form-control ${errors.tanggal ? 'is-invalid' : ''}`}
                                            value={formData.tanggal}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="Jam Sekarang">Jam Sekarang</label>
                                        <Input
                                            type="text"
                                            id="jam"
                                            name="jam"
                                            className={`form-control ${errors.jam ? 'is-invalid' : ''}`}
                                            value={formData.jam}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="namaPT">Nama PT</label>
                                        <Input
                                            type="text"
                                            id="namaPT"
                                            name="namaPT"
                                            className={`form-control ${errors.namaPT ? 'is-invalid' : ''}`}
                                            value={formData.namaPT}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="nomorPT">No. PT</label>
                                        <Input
                                            type="text"
                                            id="nomorPT"
                                            name="nomorPT"
                                            className={`form-control ${errors.nomorPT ? 'is-invalid' : ''}`}
                                            value={formData.nomorPT}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="alamatPT">Alamat. PT</label>
                                        <Input
                                            type="text"
                                            id="alamatPT"
                                            name="alamatPT"
                                            className={`form-control ${errors.alamatPT ? 'is-invalid' : ''}`}
                                            value={formData.alamatPT}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="modalDasar">Modal Dasar</label>
                                        <Input
                                            type="text"
                                            id="modalDasar"
                                            name="modalDasar"
                                            className={`form-control ${errors.modalDasar ? 'is-invalid' : ''}`}
                                            value={formatCurrency(formData.modalDasar)}
                                            onChange={handleInputChange}
                                            placeholder="Enter amount in IDR"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="modalSetorPT">Modal Setor</label>
                                        <Input
                                            type="text"
                                            id="modalSetorPT"
                                            name="modalSetorPT"
                                            className={`form-control ${errors.modalSetorPT ? 'is-invalid' : ''}`}
                                            value={formatCurrency(formData.modalSetorPT)}
                                            onChange={handleInputChange}
                                            placeholder="Enter amount in IDR"

                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="lembarSaham">Lembar Saham</label>
                                        <Input
                                            type="text"
                                            id="lembarSaham"
                                            name="lembarSaham"
                                            className={`form-control ${errors.lembarSaham ? 'is-invalid' : ''}`}
                                            value={formatCurrency(formData.lembarSaham)}
                                            onChange={handleInputChange}
                                            placeholder="Enter Jumlah Lembar Saham"

                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="kbliSelect">Select KBLI</label>
                                        <Input
                                            type="select"
                                            id="kbliSelect"
                                            onChange={handleKbliSelect}
                                            value={selectedKbli}
                                        >
                                            <option value="">Select a KBLI</option>
                                            {kbliList.map((kbli) => (
                                                <option key={kbli.KBLI} value={kbli.KBLI}>
                                                    {kbli.KBLI}: {kbli.JudulKBLI}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                    <Button color="primary" onClick={addKbli} disabled={!selectedKbli}>
                                        Add KBLI
                                    </Button>
                                    {addedKbli.length > 0 && (
                                        <div>
                                            <h5>Added KBLI</h5>
                                            <ul>
                                                {addedKbli.map((kbli, index) => (
                                                    <li key={kbli.KBLI}>
                                                        {index + 1}. {kbli.KBLI} - {kbli.JudulKBLI}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    <FormGroup>
                                        <label htmlFor="penghadapSelect">Add Penghadap</label>
                                        <Input
                                            type="select"
                                            id="penghadapSelect"
                                            onChange={handlePenghadapSelect}
                                            value={selectedPenghadap}
                                        >
                                            <option value="">Select Penghadap</option>
                                            {penghadapList.map((peng) => (
                                                <option key={peng.id} value={peng.id}>
                                                    {peng.name} (NIK: {peng.identifier})
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                    <Button
                                        color="primary"
                                        onClick={addPenghadap}
                                        disabled={!selectedPenghadap}
                                    >
                                        Add Penghadap
                                    </Button>
                                    {/* Render Added Penghadap Details */}
                                    {addedPenghadap.map((peng, index) => (
                                        <div key={peng.id}>
                                            <h6>Penghadap {index + 1}</h6>
                                            <p>
                                                <strong>Name:</strong> {peng.name} <br />
                                                <strong>NIK:</strong> {peng.identifier}
                                            </p>
                                            <FormGroup>
                                                <label>Jabatan Penghadap</label>
                                                <Input
                                                    type="select"
                                                    value={peng.jabatan}
                                                    onChange={(e) =>
                                                        updatePenghadapDetails(peng.id, 'jabatan', e.target.value)
                                                    }
                                                >
                                                    <option value="">Select Jabatan</option>
                                                    <option value="Presiden Direktur">Presiden Direktur</option>
                                                    <option value="Direktur">Direktur</option>
                                                    <option value="Presiden Komisaris">Presiden Komisaris</option>
                                                    <option value="Komisaris">Komisaris</option>
                                                    <option value="Komisaris Utama">Komisaris Utama</option>
                                                </Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <label>Pemegang Saham</label>
                                                <Input
                                                    type="select"
                                                    value={peng.pemegangSaham}
                                                    onChange={(e) =>
                                                        updatePenghadapDetails(peng.id, 'pemegangSaham', e.target.value)
                                                    }
                                                >
                                                    <option value="Tidak">Tidak</option>
                                                    <option value="Ya">Ya</option>
                                                </Input>
                                            </FormGroup>
                                            {peng.pemegangSaham === 'Ya' && (
                                                <FormGroup>
                                                    <label>Modal Disetor (IDR)</label>
                                                    <Input
                                                        type="number"
                                                        value={peng.modalDisetor}
                                                        onChange={(e) =>
                                                            updatePenghadapDetails(
                                                                peng.id,
                                                                'modalDisetor',
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Enter amount in IDR"
                                                    />
                                                </FormGroup>
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" onClick={exportMarkdown}>
                                Export Markdown
                            </Button>
                            <Button color="primary" onClick={exportToWord}>
                                Export to Word
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="6">
                    <Card>
                        <CardHeader>
                            <H5>Markdown Preview</H5>
                        </CardHeader>
                        <CardBody>
                            <ReactMarkdown rehypePlugins={[rehypeRaw, remarkGfm]}>
                                {generateMarkdown()}
                            </ReactMarkdown>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default PTForm;
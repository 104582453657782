import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { FaRegHourglass } from "react-icons/fa";
import { Spinner } from "../../AbstractElements";

const Loading = ({ title, body }) => (
  <Row className="justify-content-center" style={{ width: '100%' }}>
    <Col sm={10}>
      <Card style={{ height: "70vh" }}>
        <CardBody
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row className="text-center">
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-8' }} />
            </div>

            <h3 className="heading">{title}</h3>
            <p className="subtext">{body}</p>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default Loading;

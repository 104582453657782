import React from "react";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import PDFViewer from "./PDFViewer";

const Paragraph = ({ data }) => (
  <Col sm={6}>
    <Card>
      <CardHeader>
        <h3>Uraian Akta</h3>
      </CardHeader>
      <CardBody>
        <div dangerouslySetInnerHTML={{ __html: data }} />
        {/* <pre style={styles.paragraph}>{data}</pre> */}
      </CardBody>
    </Card>
  </Col>
);

const PreviewPdf = ({ url }) => (
  <Col sm={6}>
    <PDFViewer colSize={12} url={url} />
  </Col>
);

const PreviewDocument = ({ data, url }) => (
  <Row className="justify-content-center">
    <Paragraph data={data} />

    <PreviewPdf url={url} />
  </Row>
);

export default PreviewDocument;

import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import CustomizerContext from '../../_helper/Customizer';

import PreviewDocument from "../Scan/PreviewDocument";
import API from '../../api/services'

const DocumentDetail = () => {
  const { id } = useParams(); // Extract the :id from the URL

  const { layoutURL, setHeaderMenu } = useContext(CustomizerContext);

  const [data, setData] = useState({});

  useEffect(() => {
    setHeaderMenu('Detail Akta')

    API.akta.getDetailDocument(id)
      .then(x => {
        setData(x)

        const historyData = {
          name: `Akta ${x.name}`,
          url: `list/document/${id}`
        }
        
        API.history.insertHistory(historyData).then(x => console.log('success saved history'))
      })
      .catch(err => alert(err))
  }, [])

  const previewDocumentProps = () => ({
    data: data.paragraph,
    url: `${process.env.REACT_APP_API_BASE_URL}/public/document/${data.filePath}`,
  })

  console.log('data', data)
  return (
    <Fragment>
      <Container fluid={true}>
        {data && (
          <PreviewDocument {...previewDocumentProps()}/>
        )}
      </Container>
    </Fragment>
  );
};

export default DocumentDetail;

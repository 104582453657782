import axiosInstance from '../utils/axiosInstance';

const getListCompany = async () => {
  try {
    const response = await axiosInstance.get('/company/list');
    return response.data;
  } catch (error) {
    console.error('getListCompany failed:', error);
    throw error;
  }
};

const getDetailCompany = async (id) => {
  try {
    const response = await axiosInstance.get(`/company/detail/${id}`);
    return response.data.data;
  } catch (error) {
    console.error('getDetailCompany failed:', error);
    throw error;
  }
};

export { getListCompany, getDetailCompany };

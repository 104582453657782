import React, { Fragment, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Media,
} from "reactstrap";
import { H4, Btn } from "../../AbstractElements";

import InputData from './InputData'

const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

const ThumbnailKtp = ({ active, index }) => (
  <div
    className="px-2 py-1"
    style={{
      border: '1px solid #FFF',
      borderRadius: '8px',
      backgroundColor: active ? '#FFF' : 'transparent',
    }}
  >
    <span style={{ color: active ? '#3f3f3f' : '#FFF' }}>ID {index}</span>
  </div>
)

const PreviewImage = ({ data, image, index }) => (
  <div
    className="p-3 text-center"
    style={{
      position: 'relative',
      backgroundImage: `url('${image}')`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: '21rem',
      borderRadius: '15px',
    }}
  > 
    <div className="d-flex gap-2 position-absolute" style={{ bottom: '1rem' }}>
      {
        data.map((el, i) => (
          <ThumbnailKtp
            key={i}
            active={index === i}
            index={i + 1}
          />
        ))
      }
    </div>
  </div>
)

const Button = ({ text, onClick }) => (
  <Btn
    attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick }}
  >
    {text}
  </Btn>
)

const EditId = ({ data, onRetake, onDone }) => {
  const fileInputRef = useRef(null);
  const [selfiePhoto, setSelfiePhoto] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setFormData] = useState(data);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const base64 = await getBase64(selectedFile);
      updateFormData(base64, 'selfiePhoto')

      event.preventDefault();

      const reader = new FileReader();

      // Once the file is read, set the image source to the result
      reader.onload = (e) => {
        setSelfiePhoto(e.target.result); // Set the state with the image data URL
      };

      // Check if the file is a valid file object (Blob)
      if (selectedFile instanceof Blob) {
        reader.readAsDataURL(selectedFile); // Only call this if it's a Blob/File object
      } else {
        console.error('Invalid file type');
      }
    } else {
      alert('Only image allowed')
    }
  };

  const changeFormData = () => {
    setCurrentIndex(currentIndex+1)
  }

  const updateFormData = (value, data) => {
    const tempFormData = [...formData];
    tempFormData[currentIndex] = {
      ...tempFormData[currentIndex],
      [data]: value,
    };
    setFormData(tempFormData);
  }

  const validateSubmit = () => {
    if (formData[currentIndex]?.nik === null || formData[currentIndex]?.nik === undefined) {
      alert('NIK is empty');
      return false;
    } else if (formData[currentIndex]?.nama === null || formData[currentIndex]?.nama === undefined) {
      alert('Nama Lengkap is empty');
      return false;
    } else if (formData[currentIndex]?.tempatLahir === null || formData[currentIndex]?.tempatLahir === undefined) {
      alert('Tempat Lahir is empty');
      return false;
    } else if (formData[currentIndex]?.tglLahir === null || formData[currentIndex]?.tglLahir === undefined) {
      alert('Tanggal Lahir is empty');
      return false;
    } else if (formData[currentIndex]?.jenisKelamin === null || formData[currentIndex]?.jenisKelamin === undefined) {
      alert('Jenis Kelamin is empty');
      return false;
    } else if (formData[currentIndex]?.alamat === null || formData[currentIndex]?.alamat === undefined) {
      alert('Alamat is empty');
      return false;
    } else if (formData[currentIndex]?.rtRw === null || formData[currentIndex]?.rtRw === undefined) {
      alert('RT RW is empty');
      return false;
    } else if (formData[currentIndex]?.kelDesa === null || formData[currentIndex]?.kelDesa === undefined) {
      alert('Kel/Desa is empty');
      return false;
    } else if (formData[currentIndex]?.kecamatan === null || formData[currentIndex]?.kecamatan === undefined) {
      alert('Kecamatan is empty');
      return false;
    } else if (formData[currentIndex]?.kotaKabupaten === null || formData[currentIndex]?.kotaKabupaten === undefined) {
      alert('Kota/Kabupaten is empty');
      return false;
    } else if (formData[currentIndex]?.provinsi === null || formData[currentIndex]?.provinsi === undefined) {
      alert('Provinsi is empty');
      return false;
    } else if (formData[currentIndex]?.agama === null || formData[currentIndex]?.agama === undefined) {
      alert('Agama is empty');
      return false;
    } else if (formData[currentIndex]?.status === null || formData[currentIndex]?.status === undefined) {
      alert('Status Perkawinan is empty');
      return false;
    } else if (formData[currentIndex]?.pekerjaan === null || formData[currentIndex]?.pekerjaan === undefined) {
      alert('Pekerjaan is empty');
      return false;
    } else if (formData[currentIndex]?.tempatDibuat === null || formData[currentIndex]?.tempatDibuat === undefined) {
      alert('Tempat Dibuat is empty');
      return false;
    }

    onDone(formData);
    return true;
  }

  return (
    <Row className="justify-content-center mt-3" style={{ width: '100%' }}>
      <Col>
        <Card>
          <Form className="form theme-form" onSubmit={handleSubmit}>
            <CardBody>
              <H4 attrH4={{ className: "mt-3" }}>ID {currentIndex + 1} - {formData[currentIndex]?.nama}</H4>

              <InputData
                label="NIK"
                value={formData[currentIndex]?.nik}
                onChange={(event) => updateFormData(event.target.value, 'nik')}
              />

              <InputData
                label="Nama Lengkap"
                value={formData[currentIndex]?.nama}
                onChange={(event) => updateFormData(event.target.value, 'nama')}
              />

              <InputData
                label="Tempat Lahir"
                value={formData[currentIndex]?.tempatLahir}
                onChange={(event) => updateFormData(event.target.value, 'tempatLahir')}
              />

              <InputData
                label="Tanggal Lahir (DD-MM-YYYY)"
                value={formData[currentIndex]?.tglLahir}
                onChange={(event) => updateFormData(event.target.value, 'tglLahir')}
              />

              <InputData
                label="Jenis Kelamin"
                value={formData[currentIndex]?.jenisKelamin}
                onChange={(event) => updateFormData(event.target.value, 'jenisKelamin')}
              />

              <InputData
                label="Alamat"
                value={formData[currentIndex]?.alamat}
                onChange={(event) => updateFormData(event.target.value, 'alamat')}
              />

              <InputData
                label="RT/RW"
                value={formData[currentIndex]?.rtRw}
                onChange={(event) => updateFormData(event.target.value, 'rtRw')}
              />

              <InputData
                label="Desa/Kelurahan"
                value={formData[currentIndex]?.kelDesa}
                onChange={(event) => updateFormData(event.target.value, 'kelDesa')}
              />

              <InputData
                label="Kecamatan"
                value={formData[currentIndex]?.kecamatan}
                onChange={(event) => updateFormData(event.target.value, 'kecamatan')}
              />

              <InputData
                label="Kota/Kabupaten"
                value={formData[currentIndex]?.kotaKabupaten}
                onChange={(event) => updateFormData(event.target.value, 'kotaKabupaten')}
              />

              <InputData
                label="Provinsi"
                value={formData[currentIndex]?.provinsi}
                onChange={(event) => updateFormData(event.target.value, 'provinsi')}
              />

              <InputData
                label="Agama"
                value={formData[currentIndex]?.agama}
                onChange={(event) => updateFormData(event.target.value, 'agama')}
              />

              <InputData
                label="Status Perkawninan"
                value={formData[currentIndex]?.status}
                onChange={(event) => updateFormData(event.target.value, 'status')}
              />

              <InputData
                label="Pekerjaan"
                value={formData[currentIndex]?.pekerjaan}
                onChange={(event) => updateFormData(event.target.value, 'pekerjaan')}
              />

              <InputData
                label="Tempat Dibuat"
                value={formData[currentIndex]?.tempatDibuat}
                onChange={(event) => updateFormData(event.target.value, 'tempatDibuat')}
              />

              {/* <Row className="mt-3">
                <Col>
                  <FormGroup>
                    <label className="form-label">Selfie Photo</label>
                    {selfiePhoto && (
                      <>
                        <Media
                          src={selfiePhoto}
                          className="p-3"
                          style={{
                            width: '100%', height: '200px',
                            border: '1px solid #efefef',
                            borderRadius: '8px',
                          }}
                          alt=""
                          onClick={() => fileInputRef.current.click()}
                        />
                      </>
                    )}

                    {!selfiePhoto && (
                      <Button
                        color="primary"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => fileInputRef.current.click()}
                      >
                        Upload Selfie photo
                      </Button>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              

              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: 'none' }} // Hide the input element
                onChange={handleFileChange}
              /> */}
            </CardBody>

            <CardFooter className="text-end">
              {
                currentIndex === (data.length - 1)
                ? (
                  <Button
                    onClick={validateSubmit}
                    text="Buat Uraian ID"
                  />
                )
                : (
                  <Button
                    onClick={changeFormData}
                    text="Selanjutnya"
                  />
                )
              }      
            </CardFooter>
          </Form>
        </Card>
      </Col>

      <Col>
        <Card>
          <PreviewImage
            index={currentIndex}
            data={formData}
            image={formData[currentIndex].ktpPhoto}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default EditId;

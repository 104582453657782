import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardHeader, CardBody, Button, CardFooter } from "reactstrap";
import { Close as CloseIcon } from '@mui/icons-material';
import { useDropzone } from "react-dropzone";

import { H5 } from "../../AbstractElements";
import InputData from './InputData';

const FileInfo = ({ file }) => (
  <div style={styles.fileInfo}>
    <p className="m-0" style={styles.fileName}>{file.file.name}</p>
    <p className="m-0" style={styles.fileSize}>
      {
        file.file.size === 'Database ID'
        ? 'Database ID'
        : `${(file.file.size / 1024).toFixed(1)} KB`
      }
    </p>
  </div>
);

const UploadedItem = ({ file, onRemove, type="id" }) => (
  <div className="d-flex mb-3" style={styles.fileCard}>
    <Row className="justify-content-center align-items-center p-3" style={{ flex: 1 }}>
      <Col sm={3}>
        {type === "document" && (
          <i class="fa fa-folder" style={{ fontSize: '3rem' }}></i>
        )}
        {(type === "id" || type === "verify") && (
          <img src={file.preview} style={{ width: '100%', height: '100%', maxHeight: '6rem', objectFit: 'contain' }}/>
        )}
      </Col>
      
      <Col sm={7}>
        <FileInfo file={file} />
      </Col>

      <Col sm={2} className="d-flex justify-content-end">
        <button
          style={styles.removeButton}
          onClick={(e) => onRemove(e)}
        >
          <CloseIcon style={{ flex: 1, fontSize: 14, color: '#FFFFFF' }} />
        </button>
      </Col>
    </Row>
  </div>
)

const Upload = ({
  title,
  subtitle,
  onSubmit,
  data=[],
  dataOri=[],
  onChangeDropdown=() => {},
  maxFile=2,
  type="id",
}) => {
  const [files, setFiles] = useState([]);

  // Handle drop event
  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => {
      const fileName = file.name;
      const fileSize = file.size;
      const fileLastModified = file.lastModified;
      
      const isExist = files.filter(x =>
        x.file.name === fileName
        && x.file.size === fileSize
        && x.file.lastModified === fileLastModified
      )[0];
      if (isExist !== undefined) {
        return null;
      }

      return {
        file,
        preview: URL.createObjectURL(file),
      };
    }).filter(Boolean);

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // Handle file removal
  const removeFile = (e, index) => {
    e.stopPropagation();
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Dropzone setup
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: type === "id" ? {
      'image/*': ['.png', '.jpg', '.jpeg'],
    } : {
      'application/pdf': ['.pdf'],
    },
  });

  const dropzoneRootProps = (
    getRootProps({ style: {...styles.dropzone, minHeight: files.length > 0 ? '10rem' : '14rem'} })
  )

  return (
    <Col sm={10}>
      <Card>
        <CardHeader>
          <H5>{title}</H5>
          <p className="m-0">{subtitle}</p>
        </CardHeader>

        <CardBody>
          {files.map((file, index) => (
            <UploadedItem
              key={index}
              file={file}
              type={type}
              onRemove={(e) => removeFile(e, index)}
            />
          ))}

          {type === "verify" && (
            <div className="mt-3">
              <InputData
                data={data}
                label="Pilih ID dari Database"
                placeholder="Pilih ID dari Database"
                type="dropdown"
                onChange={(event) => {
                  onChangeDropdown(event.target.value);

                  const foundData = dataOri.filter(el => el.id === event.target.value)[0];

                  if (foundData !== undefined) {
                    const test = [
                      {
                        preview: foundData.ktpPhoto,
                        file: {
                          name: foundData.name,
                          size: 'Database ID'
                        }
                      }
                    ]
                    setFiles(test)
                  }
                }}
              />
            </div>
          )}

          {type !== "verify" && files.length < maxFile && (
            <div {...dropzoneRootProps}>
              <input {...getInputProps()} />
              <i className="fa fa-cloud-upload" style={{fontSize: '4rem'}}></i>
              {files.length > 0 && (
                <p>Drop files here or click to upload</p>
              )}

              {files.length === 0 && (
                <p className="m-0">Drop files here or click to upload</p>
              )}
            </div>
          )}

          {type === "document" && (
            <div className="mt-3">
              <InputData
                data={data}
                label="Pilih Kategori Akta"
                type="dropdown"
                onChange={(event) => onChangeDropdown(event.target.value)}
              />
            </div>
          )}
        </CardBody>

        <CardFooter>
          <div className="d-flex justify-content-end">
            <button style={styles.scanButton} onClick={() => onSubmit(files)}>Scan Sekarang</button>
          </div>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default Upload;

// Inline styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '1rem',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  fileCard: {
    height: '8rem',
    borderRadius: '10px',
    alignItems: 'center',
    borderRadius: '10px',
    backgroundColor: '#d0d0d0',
    padding: 0,
  },
  fileInfo: {
    padding: '5px',
    fontSize: '12px',
    textAlign: 'left',
  },
  fileName: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#000',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fileSize: {
    color: '#888',
    fontSize: '10px',
  },
  removeButton: {
    background: "#4a4a4a",
    cursor: "pointer",
    zIndex: 1,
    padding: "0",
    margin: "0",
    border: "0",
    borderRadius: "50%",
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
  },
  dropzone: {
    padding: '20px',
    borderRadius: '10px',
    cursor: 'pointer',
    color: '#666',
    textAlign: 'center',
    alignContent: 'center',
    border: '1px dashed',
  },
  scanButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#555555',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};
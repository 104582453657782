import React, { Fragment, useContext, useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H4 } from "../../AbstractElements";

import CustomizerContext from '../../_helper/Customizer';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import API from '../../api/services'

const tableColumns = [
  {
    name: '#',
    selector: row => row.row_number,
    sortable: true,
    center: true,
  },
  {
    name: 'Nama Akta',
    selector: row => row.name,
    sortable: false,
    center: true,
  },
  {
    name: 'Kategori Akta',
    selector: row => row.document_type,
    sortable: true,
    center: true,
  },
  {
    name: 'Tgl. Upload',
    selector: row => row.created_at,
    sortable: true,
    center: true,
  },
  {
    name: 'Status',
    selector: row => row?.status ?? 'Completed',
    sortable: false,
    center: true,
  },
];

const DocumentList = () => {
  const navigate = useNavigate();
  const { layoutURL, setHeaderMenu } = useContext(CustomizerContext);

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [data, setData] = useState([]);


  useEffect(() => {
    setHeaderMenu('List Akta')
  
    API.akta.getListDocument()
      .then(x => {
        console.log('x', x)
        setData(x.data)
      })
      .catch(x => alert(x))
  }, [])

  const handleRowClick = (row) => {
    navigate(`/list/document/${row.id}/${layoutURL}`); // Navigate to details page with row ID
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  // const handleDelete = () => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to delete:\r ${selectedRows.map(
  //         (r) => r.title
  //       )}?`
  //     )
  //   ) {
  //     setToggleDelet(!toggleDelet);

  //     setData(
  //       data.filter((item) =>
  //         selectedRows.filter((elem) => elem.id === item.id).length > 0
  //           ? false
  //           : true
  //       )
  //     );
  //     setSelectedRows("");
  //   }
  // };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {/* {selectedRows.length !== 0 && (
                  <div
                    className={`d-flex align-items-center justify-content-between bg-light-info p-2`}
                  >
                    <H4 attrH4={{ className: "text-muted m-0" }}>
                      Delet Selected Data..!
                    </H4>
                    <Btn attrBtn={{ color: "danger", onClick: () => handleDelete() }}>
                      Delete
                    </Btn>
                  </div>
                )} */}

                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleDelet}
                  onRowClicked={handleRowClick} // Add onRowClicked event
                  pointerOnHover // Highlight row on hover
                  highlightOnHover // Highlight row on hover
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DocumentList;

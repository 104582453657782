import React, { Fragment, useEffect, useContext, useState } from "react";
import { Container } from "reactstrap";

import { Breadcrumbs } from "../../AbstractElements";
import Upload from './Upload';
import Loading from './Loading';
import PDFViewer from './PDFViewer';
import PreviewDocument from "./PreviewDocument";

import CustomizerContext from "../../_helper/Customizer";
import API from '../../api/services'

const documentDropdownData = [
  {
    value: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    label: 'Akta Pendirian Perusahaan'
  },
  {
    value: 'c1a3e5f8-1ad5-4d86-8b58-1d2cb7d68fcb',
    label: 'Akta Jual Beli'
  }
]

const Document = () => {
  const { toggleSidebarResponsive, setHeaderMenu } = useContext(CustomizerContext);

  const [documentTypeId, setDocumentTypeId] = useState('');
  const [isProcessingOcr, setIsProcessingOcr] = useState(false);
  const [isFinishedOcr, setIsFinishedOcr] = useState(false);

  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    setHeaderMenu('Scan Akta')
  }, [])

  useEffect(() => {
    // Set document type id to the first value of dropdown data
    setDocumentTypeId(documentDropdownData[0].value)

    toggleSidebarResponsive(true);

    API.akta.getListDocument().then((x) => console.log(x))
  }, [])

  const handleChooseDocument = async (files) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      console.log('handleChooseDocument file', file)

      if (file && file.file.type.startsWith('application/pdf')) {
        setPdfFile(file.file)

        // Create a blob URL for the uploaded file
        const fileUrl = URL.createObjectURL(file.file);
        setPdfUrl(fileUrl); // Set the blob URL to state
      }
    }
  };

  const handleUploadDocument = async () => {
    setIsProcessingOcr(true);
    const formData = new FormData();
    formData.append('pdf', pdfFile);
    formData.append('documentTypeId', documentTypeId);
    formData.append('fileName', pdfFile.name);

    try {
      const response = await API.akta.uploadDocuments(formData);
      console.log('response', response)

      if (response) {
        const data = response?.data?.replace('html', '');
        setPdfUrl(`${process.env.REACT_APP_API_BASE_URL}/public/document/${response.filePath}`);
        setIsProcessingOcr(false);
        setIsFinishedOcr(true);
        setDocumentData(data);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(error);
    }
  };

  const getBreadcrumbsProp = () => {
    const mainTitle = 'Scan Akta';

    let result = {
      mainTitle,
      parent: 'Scan Akta',
    };

    return result;
  }

  return (
    <Fragment>
      <Container
        fluid={true}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        {!documentData && !pdfUrl && !isProcessingOcr && !isFinishedOcr && (
          <Upload
            title="Upload & Scan Akta"
            subtitle="Pilih dan upload file akta yang ingin kamu scan"
            onSubmit={handleChooseDocument}
            data={documentDropdownData}
            onChangeDropdown={(value) => setDocumentTypeId(value)}
            type="document"
            maxFile={1}
          />
        )}

        {!documentData && pdfUrl && !isProcessingOcr && !isFinishedOcr && (
          <PDFViewer
            fileName={pdfFile.name}
            url={pdfUrl}
            onScan={handleUploadDocument}
          />
        )}

        {isProcessingOcr && (
          <Loading
            title="Hang Tight!"
            body="Our AI is working its magic on your document"
          />
        )}

        {isFinishedOcr && (
          <PreviewDocument
            data={documentData}
            url={pdfUrl}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default Document;

import axiosInstance from "../utils/axiosInstance";

const getListDocument = async () => {
  try {
    const response = await axiosInstance.get("/document/list");

    return response.data;
  } catch (error) {
    console.error("Get list document failed:", error);
    throw error;
  }
};

const getDetailDocument = async (id) => {
  try {
    const response = await axiosInstance.get(`/document/detail/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("getDetailDocument failed:", error);
    throw error;
  }
};

const uploadDocuments = async (formData) => {
  try {
    const response = await axiosInstance.post("/document/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

const exportDocumentToWord = async (markdown) => {
  try {
    const response = await axiosInstance.post("/document/process-markdown", { markdown }, {
      responseType: 'blob',
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};



export { getListDocument, getDetailDocument, uploadDocuments, exportDocumentToWord };

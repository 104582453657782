import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const Dropdown = ({
  label,
  data,
  disabled,
  onChange,
}) => (
  <Row>
    <Col>
      <FormGroup>
        <Label>{label}</Label>
        <Input
          type="select"
          name="select"
          className="form-control digits"
          placeholder={label}
          defaultValue={null}
          onChange={onChange}
          disabled={disabled}
        >
          {data}
        </Input>
      </FormGroup>
    </Col>
  </Row>
)

const TextInput = ({
  label,
  value,
  disabled,
  onChange,
}) => (
  <Row className="mt-3">
    <Col>
      <FormGroup className="row">
        <Label className="col-sm-4 col-form-label">{label}</Label>
        <Col sm="8">
          <Input
            className="form-control"
            placeholder={label}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </Col>
      </FormGroup>

      {/* <FormGroup>
        <Label>{label}</Label>
        <Input
          className="form-control"
          placeholder={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </FormGroup> */}
    </Col>
  </Row>
)

const InputData = ({
  label,
  value,
  data,
  onChange,
  placeholder='',
  disabled=false,
  type="text",
}) => {
  if (type === "dropdown") {
    let newOptions = data.map((el, index) => (
      <option key={index} value={el.value}>{el.label}</option>
    ));

    if (placeholder) {
      const defaultOption = <option key={100} disabled value="" selected>{placeholder}</option>

      newOptions = [
        defaultOption,
        ...newOptions
      ]
    }

    return (
      <Dropdown
        label={label}
        data={newOptions}
        disabled={disabled}
        onChange={onChange}
      />
    )
  }

  return (
    <TextInput
      label={label}
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  )
};

export default InputData;

import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, Image, H4, H5 } from "../../AbstractElements";
import DropdownCommon from '../Common/Dropdown';
import InputData from '../Scan/InputData'
import API from '../../api/services'
import { BorderRadius } from "../../Constant";

const DATA_BOARD_DIRECTOR = [
  {
    selfiePhoto: require('../../assets/images/user/7.jpg'),
    name: 'Budi Santoso',
    company: 'PT Harmoni Kreatif Mandiri',
    title: 'Komisaris'
  },
  {
    selfiePhoto: require('../../assets/images/user/7.jpg'),
    name: 'Rina Fadhila',
    company: 'PT Indah Maju jaya',
  },
  {
    selfiePhoto: require('../../assets/images/user/7.jpg'),
    name: 'Budi Santoso',
    company: 'PT Harmoni Kreatif Mandiri',
    title: 'Direktur'
  }
]

const DATA_AKTA = [
  {
    date: 'Nov 20, 24',
    companyName: 'PT Indah Perkasa Putra',
    documentType: 'Akta Pendirian Perusahaan',
  },
  {
    date: 'Nov 20, 24',
    companyName: 'PT Marvinda Utara',
    documentType: 'Akta Jual Beli',
  }
]

const DEFAULT_COMPANY_DETAIL_DATA = {
  detail: {
    registrationNumber: '123456789',
    companyName: 'PT Harmoni Kreatif Mandiri',
    npwp: '08.12321312.21321',
    address: 'Jl. Testing',
    city: 'Kab. Bandung'
  },
  document: DATA_AKTA,
  people: DATA_BOARD_DIRECTOR,
}

const ItemSeparator = () => (
  <div style={styles.separator} />
)

const AktaItem = ({ item }) => (
  <Row>
    <Col className="justify-content-between" sm="12">
      <div className='header-top gap-0'>
        <div>
          <span style={{ fontSize: '12px', color: '#787878' }}>{item.date}</span>
          <p className="mb-1">Akta {item.companyName}</p>
          <span style={{ fontSize: '12px', color: '#787878' }}>{item.documentType}</span>
        </div>

        <DropdownCommon
          dropdownMain={{ className: 'icon-dropdown', direction: 'start' }}
          options={["Weekly", "Monthly", "Yearly"]}
          iconName='icon-more-alt'
          btn={{ tag: 'span' }}
        />
      </div>
      
      <ItemSeparator />
    </Col>
  </Row>
)

const boardDirectorImageProps = (imagePath) => ({
  className: 'img-50',
  alt: '',
  src: imagePath,
  style: {
    borderRadius: '8px',
  }
})

const BoardDirector = ({ item }) => (
  <Row>
    <Col className="justify-content-between" sm="12">
      <div className='header-top gap-0'>
        <div className="d-flex align-items-center gap-2 me-2">
          <img {...boardDirectorImageProps(item.selfiePhoto)} />

          <div>
            <p className="mb-1">{item.name}</p>
            <p className="mb-1" style={{ color: '#787878' }}>{item.company}</p>
            {item.title && (
              <p className="mb-1" style={{ color: '#787878' }}>{item.title}</p>
            )}
          </div>
        </div>

        <DropdownCommon
          dropdownMain={{ className: 'icon-dropdown', direction: 'start' }}
          options={["Weekly", "Monthly", "Yearly"]}
          iconName='icon-more-alt'
          btn={{ tag: 'span' }}
        />
      </div>

      <ItemSeparator />
    </Col>
  </Row>
)

const CompanyCard = ({ title, children }) => (
  <Card>
    <CardHeader className='card-no-border pb-0'>
      <H5>{title}</H5>
    </CardHeader>

    <CardBody>
      {children}
    </CardBody>
  </Card>
)

const CompanyDetail = () => {
  const { id } = useParams(); // Extract the :id from the URL

  const [data, setData] = useState(DEFAULT_COMPANY_DETAIL_DATA);

  useEffect(() => {
    // API.akta.getDetailDocument(id)
    //   .then(x => setData(x))
    //   .catch(err => alert(err))
  }, [])

  const breadcrumbsProps = () => ({
    mainTitle: 'Detail Akta',
    parent: 'List Akta',
    title: 'Detail Akta'
  });

  const previewDocumentProps = () => ({
    data: data.paragraph,
    url: `${process.env.REACT_APP_BASE_URL}/public/document/${data.filePath}`,
  })

  return (
    <Fragment>
      <Breadcrumbs {...breadcrumbsProps()}/>

      <Container fluid={true}>
        <Row>
          <Col sm="8">
            <Card>
              <CardHeader className='card-no-border pb-0'>
                <H5>Identitas Perusahaan</H5>
              </CardHeader>

              <CardBody>
                {
                  Object.keys(data.detail).length > 0 && (
                    <>
                      <InputData
                        label="No. Registrasi"
                        value={data.detail.registrationNumber}
                        disabled
                      />

                      <InputData
                        label="Nama Perusahaan"
                        value={data.detail.companyName}
                        disabled
                      />

                      <InputData
                        label="NPWP"
                        value={data.detail.npwp}
                        disabled
                      />

                      <InputData
                        label="Alamat Perusahaan"
                        value={data.detail.address}
                        disabled
                      />

                      <InputData
                        label="Kota/Kabupaten"
                        value={data.detail.city}
                        disabled
                      />
                    </>
                  )
                }
              </CardBody>
            </Card>
          </Col>

          <Col sm="4">
            <Row>
              <Col sm="12">
                <CompanyCard
                  title="List Akta"
                  children={data.document.map((item, index) => (
                    <AktaItem key={index} item={item}/>
                  ))}
                />
              </Col>

              <Col sm="12">
                <CompanyCard
                  title="Para Pihak"
                  children={data.people.map((item, index) => (
                    <BoardDirector key={index} item={item}/>
                  ))}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const styles = {
  separator: {
    borderBottom: '1px solid #e9e9e9',
    marginBottom: '1rem',
    paddingBottom: '1rem',
  },
}
export default CompanyDetail;

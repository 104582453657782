import * as KTPApi from './ktpService';
import * as DocumentAPI from './docService';
import * as CompanyAPI from './companyService';
import * as HistoryAPI from './historyService';

const API = {
  ktp: KTPApi,
  akta: DocumentAPI,
  company: CompanyAPI,
  history: HistoryAPI,
};

export default API;

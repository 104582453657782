// home
import Home from "../Components/Home";
import ScanCreate from "../Components/Home/ScanCreate";

import ScanIdentifier from "../Components/Scan/Identifier";
import DocumentIdentifier from "../Components/Scan/Document";

import VerifyIdentifier from "../Components/Scan/VerifyIdentifier";

import IdentifierList from "../Components/TableList/IdentifierList";
import IdentifierDetail from "../Components/TableList/IdentifierDetail";

import DocumentList from "../Components/TableList/DocumentList";
import DocumentDetail from "../Components/TableList/DocumentDetail";

import CompanyList from "../Components/TableList/CompanyList";
import CompanyDetail from "../Components/TableList/CompanyDetail";

import CreateDocument from "../Components/Editor/MDEEditor/MDEEditor";


export const routes = [
  { path: `${process.env.PUBLIC_URL}/home/:layout`, Component: <Home /> },
  { path: `${process.env.PUBLIC_URL}/scan-create/:layout`, Component: <ScanCreate /> },

  { path: `${process.env.PUBLIC_URL}/scan/id/:layout`, Component: <ScanIdentifier /> },
  { path: `${process.env.PUBLIC_URL}/scan/document/:layout`, Component: <DocumentIdentifier /> },
  { path: `${process.env.PUBLIC_URL}/create/document/:layout`, Component: <CreateDocument /> },

  { path: `${process.env.PUBLIC_URL}/verify/id/:layout`, Component: <VerifyIdentifier /> },

  { path: `${process.env.PUBLIC_URL}/list/id/:layout`, Component: <IdentifierList /> },
  { path: `${process.env.PUBLIC_URL}/list/id/:id/:layout`, Component: <IdentifierDetail /> },
  { path: `${process.env.PUBLIC_URL}/list/document/:layout`, Component: <DocumentList /> },
  { path: `${process.env.PUBLIC_URL}/list/document/:id/:layout`, Component: <DocumentDetail /> },
  { path: `${process.env.PUBLIC_URL}/list/company/:layout`, Component: <CompanyList /> },
  { path: `${process.env.PUBLIC_URL}/list/company/:id/:layout`, Component: <CompanyDetail /> },

];

import axios from 'axios';
import axiosInstance from '../utils/axiosInstance';

const getListKtp = async () => {
  try {
    const response = await axiosInstance.get('/id/list');
    return response.data;
  } catch (error) {
    console.error('getListKtp failed:', error);
    throw error;
  }
};

const getDetailKtp = async (id) => {
  try {
    console.log('getDetailKtp', getDetailKtp)
    const response = await axiosInstance.get(`/id/detail/${id}`);
    return response.data.data;
  } catch (error) {
    console.error('getDetailKtp failed:', error);
    throw error;
  }
};

const uploadKtp = async (photos) => {
  try {
    const response = await axiosInstance.post('/id/extract-new', { photos }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('response', response.data)
    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

const updateKtp = async (data) => {
  try {
    const response = await axiosInstance.post('/id/update', { data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('response', response.data)
    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
}

const verifyKtp = async (data) => {
  try {
    const response = await axiosInstance.post('/id/verify-identifier', { data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('response', response.data)
    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
}

const saveParagraph = async (data) => {
  try {
    const response = await axiosInstance.post('/id/paragraph/save', { data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('response', response.data)
    return response.data;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
}

export { getListKtp, getDetailKtp, uploadKtp, updateKtp, verifyKtp, saveParagraph };

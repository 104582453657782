import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Media,
  Container,
} from "reactstrap";
import { FaShareAlt, FaCopy } from 'react-icons/fa';

import { Breadcrumbs, H4 } from "../../AbstractElements";
import CustomizerContext from '../../_helper/Customizer';
import InputData from '../Scan/InputData'
import API from '../../api/services'


const numberToTerbilang = (number) => {
  const words = [
    "", "satu", "dua", "tiga", "empat", "lima", "enam", "tujuh", "delapan", "sembilan", "sepuluh", "sebelas"
  ];
  if (number < 12) return words[number];
  else if (number < 20) return words[number - 10] + " belas";
  else if (number < 100) return words[Math.floor(number / 10)] + " puluh " + words[number % 10];
  else throw new Error("This function only supports numbers below 100.");
}

const yearToTerbilang = (year) => {
  const data = numberToTerbilang(Math.floor(year / 1000));
  const thousands = data === 'satu' ? 'seribu' : `${data} ribu`;
  const hundreds = numberToTerbilang(Math.floor((year % 1000) / 100)) + " ratus";
  const tens = numberToTerbilang(year % 100);
  return `${thousands} ${hundreds} ${tens}`.trim();
}

const convertDateToTerbilang = (tglLahir) => {
  try {
    const formattedDate = tglLahir.split('-').reverse().join('-');
    const dateObj = new Date(formattedDate);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const year = dateObj.getFullYear();

    const dayTerbilang = numberToTerbilang(day);
    const yearTerbilang = yearToTerbilang(year);

    const indonesianMonths = {
      "January": "Januari", "February": "Februari", "March": "Maret",
      "April": "April", "May": "Mei", "June": "Juni", "July": "Juli",
      "August": "Agustus", "September": "September", "October": "Oktober",
      "November": "November", "December": "Desember",
    };

    const monthIndonesian = indonesianMonths[month] || month;
    return `${dayTerbilang} ${monthIndonesian} ${yearTerbilang}`;

  } catch (err) {
    return `Invalid date format: ${err.message}`;
  }
}

// Helper function to capitalize the first letter
const capitalize = (str) => {
  if (!str) return '';
  return str.toLowerCase().split(' ').map(function(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
};

const generateAktaFormat = (placeholders) => (
  `${placeholders?.gelar} ${placeholders?.nama}, lahir di ${placeholders?.tempatLahir}, pada tanggal ${placeholders?.tglLahir} (${placeholders?.tglLahir_terbilang}), Warga Negara Indonesia, ${placeholders?.pekerjaan}, bertempat tinggal di ${placeholders?.kotaKabupaten}, ${placeholders?.alamat}, Rukun Tetangga ${placeholders?.rt}, Rukun Warga ${placeholders?.rw}, Kelurahan ${placeholders?.kelDesa}, Kecamatan ${placeholders?.kecamatan}, ${placeholders?.provinsi}, pemegang Kartu Tanda Penduduk dengan Nomor Induk Kependudukan: ${placeholders?.nik}`
);

const ActionButton = ({
  onClick,
  style={},
  additionalClass={},
  outline = false,
  children
}) => {
  let className = `d-flex py-2 px-3 align-items-center ${additionalClass}`
  
  return (
    <Button
      className={className}
      style={{
        backgroundColor: '#E6E6E6',
        ...style,
      }}
      outline={outline}
      onClick={onClick}
      color="light"
    >
      {children}
    </Button>
  )
}

const IdentifierDetail = () => {
  const { setHeaderMenu } = useContext(CustomizerContext);
  const { id } = useParams(); // Extract the :id from the URL

  const [data, setData] = useState({});
  const [paragraph, setParagraph] = useState({});
  const [copiedText, setCopiedText] = useState(false);

  useEffect(() => {
    setHeaderMenu('Detail ID')

    API.ktp.getDetailKtp(id)
      .then(x => {
        setData(x);

        const historyData = {
          name: `KTP ${x.nama}`,
          url: `list/id/${id}`
        }

        API.history.insertHistory(historyData).then(x => console.log('success saved history'))
      })
      .catch(err => alert(err))

  }, [])

  useEffect(() => {
    if (Object.keys(paragraph).length === 0 && Object.keys(data).length > 0) {
      const gelar = capitalize(data.jenisKelamin.toLowerCase());
      const tempatLahir = capitalize(data.tempatLahir.toLowerCase());
      const pekerjaan = capitalize(data.pekerjaan.toLowerCase());
      const kotaKabupaten = capitalize(data.kotaKabupaten.toLowerCase());
      const alamat = capitalize(data.alamat.toLowerCase());
      const kelDesa = capitalize(data.kelDesa.toLowerCase());
      const kecamatan = capitalize(data.kecamatan.toLowerCase());
      const provinsi = capitalize(data.provinsi.toLowerCase());

      const tglTerbilang = convertDateToTerbilang(data.tglLahir);

      const newData = {
        ...data,
        gelar,
        tempatLahir,
        pekerjaan,
        kotaKabupaten,
        alamat,
        kelDesa,
        kecamatan,
        provinsi,
        tglLahir_terbilang: tglTerbilang
      };

      setParagraph({
        body: generateAktaFormat(newData),
        ...newData
      });
    }
  }, [data])

  const handleShare = (index) => {
    console.log("Share card:", index);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    
    setCopiedText(true);
  };

  return (
    <Fragment>
      <Container fluid={true} className="pt-3">
        <Row>
          <Col sm="8">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center gap-3">
                  <H4 attrH4={{ className: "mt-3" }}>ID Card</H4>

                  {data.verifiedStatus && (
                    <div>
                      <span className="me-2">Verified by</span>
                      <img src={require("../../assets/images/logo/logo-verihubs.png")} style={{ height: '15px' }}/>
                    </div>
                  )}
                </div>

                {
                  Object.keys(data).length > 0 && (
                    <>
                      <InputData
                        label="NIK"
                        value={data.nik}
                        disabled
                      />

                      <InputData
                        label="Nama Lengkap"
                        value={data.nama}
                        disabled
                      />

                      <InputData
                        label="Tempat Lahir"
                        value={data.tempatLahir}
                        disabled
                      />

                      <InputData
                        label="Tanggal Lahir (DD-MM-YYYY)"
                        value={data.tglLahir}
                        disabled
                      />

                      <InputData
                        label="Jenis Kelamin"
                        value={data.jenisKelamin}
                        disabled
                      />

                      <InputData
                        label="Alamat"
                        value={data.alamat}
                        disabled
                      />

                      <InputData
                        label="RT/RW"
                        value={data.rtRw}
                        disabled
                      />

                      <InputData
                        label="Desa/Kelurahan"
                        value={data.kelDesa}
                        disabled
                      />

                      <InputData
                        label="Kecamatan"
                        value={data.kecamatan}
                        disabled
                      />

                      <InputData
                        label="Kota/Kabupaten"
                        value={data.kotaKabupaten}
                        disabled
                      />

                      <InputData
                        label="Provinsi"
                        value={data.provinsi}
                        disabled
                      />

                      <InputData
                        label="Agama"
                        value={data.agama}
                        disabled
                      />

                      <InputData
                        label="Status Perkawninan"
                        value={data.status}
                        disabled
                      />

                      <InputData
                        label="Pekerjaan"
                        value={data.pekerjaan}
                        disabled
                      />

                      <InputData
                        label="Tempat Dibuat"
                        value={data.tempatDibuat}
                        disabled
                      />
                    </>
                  )
                }
              </CardBody>
            </Card>
          </Col>

          <Col sm="4">
            {Object.keys(data).length > 0 && (
              <Row>
                <Col>
                  <Card style={{
                    backgroundImage: `url('${data.ktpPhoto}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "30vh",
                  }}>
                  </Card>
                </Col>
              </Row>
            )}
            
            {Object.keys(paragraph).length > 0 && (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <p>{paragraph.gelar} <strong>{paragraph.nama}</strong>, lahir di {paragraph.tempatLahir}, pada tanggal {paragraph.tglLahir} ({paragraph.tglLahir_terbilang}), Warga Negara Indonesia, {paragraph.pekerjaan}, bertempat tinggal di {paragraph.kotaKabupaten}, {paragraph.alamat}, Rukun Tetangga {paragraph.rt}, Rukun Warga {paragraph.rw}, Kelurahan {paragraph.kelDesa}, Kecamatan {paragraph.kecamatan}, {paragraph.provinsi}, pemegang Kartu Tanda Penduduk dengan Nomor Induk Kependudukan: {paragraph.nik}</p>

                      <div className="d-flex justify-content-end">
                        <ActionButton
                          additionalClass="me-3"
                          outline
                          onClick={() => handleShare(id)}
                        >
                          <FaShareAlt size={16} color="#373737"/>
                        </ActionButton>

                        <ActionButton
                          outline
                          onClick={() => handleCopy(paragraph.body)}
                        >
                          {
                            copiedText
                          ? (
                            <span className="ms-1" style={{ color: '#000' }}>
                            Copied!
                            </span>
                          )
                          : (
                            <>
                              <FaCopy size={16} color="#000"/>
                              <span className="ms-1" style={{ color: '#000' }}>
                                Copy
                              </span>
                            </>
                          )
                        }
                        </ActionButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default IdentifierDetail;

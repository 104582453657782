import React, { Fragment, useContext, useRef, useEffect, useState } from "react";
import { Container, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import CustomizerContext from "../../_helper/Customizer";
import Upload from "./Upload";

import API from '../../api/services'
import Loading from './Loading'
import "./VerifyIdentifier.css";

const MOCKUP_DATA = [
  {
    name: 'Ghofar Wijaya',
    isVerified: true,
    verificationDate: '24 November 2024',
    verificationCode: 'VRHASD12321321',
    ktpPhoto: 'https://via.placeholder.com/500',
    identifier: '871263891279312',
    birthDetail: 'Bandung, 31 Maret 1990',
    gender: 'Laki-Laki',
    securityCheck: {
      identifier: true,
      name: true,
      birthDate: true,
    }
  },
  {
    name: 'Rini Wijaya',
    isVerified: false,
    verificationDate: '24 November 2024',
    verificationCode: 'VRHASD12321321',
    ktpPhoto: 'https://via.placeholder.com/500',
    identifier: '871263891279312',
    birthDetail: 'Bandung, 31 Maret 1990',
    gender: 'Laki-Laki',
    securityCheck: {
      identifier: true,
      name: true,
      birthDate: false,
    }
  }
]

const ItemSeparator = () => (
  <div style={styles.separator} />
)

const ProfileCard = ({ item }) => (
  <div className="profile-card mb-2">
    <img
      src={item.ktpPhoto}
      alt="Profile"
      className="profile-image"
    />
    <div className="profile-details">
      <div className="profile-row">
        <span className="label">NIK</span>
        <span className="value">{item.identifier}</span>
      </div>
      <div className="profile-row">
        <span className="label">Nama</span>
        <span className="value">{item.name}</span>
      </div>
      <div className="profile-row">
        <span className="label">Tempat, Tanggal Lahir</span>
        <span className="value">{item.birthDetail}</span>
      </div>
      <div className="profile-row">
        <span className="label">Jenis Kelamin</span>
        <span className="value">{item.gender}</span>
      </div>
    </div>
  </div>
);

const SecurityResult = ({ value }) => (
  value ? (
    <span className="security-value-success">
      TRUE <i className="fa fa-check-circle"></i>
    </span>
  ) : (
    <span className="security-value-failed">
      FALSE <i className="fa fa-times-circle"></i>
    </span>
  )
);

const SecurityCheck = ({ item }) => {
  const securityCheckRef = useRef(null);
  const [securityCheckHeight, setSecurityCheckHeight] = useState(0);

  useEffect(() => {
    if (securityCheckRef.current) {
      setSecurityCheckHeight(securityCheckRef.current.offsetHeight);
    }
  }, [])

  return (
    <div className="d-flex gap-3 align-items-stretch mt-2">
      <img
        src={item.ktpPhoto} // Replace this with the real image URL
        alt="Profile"
        className="identifier-image"
        style={{ height: `${securityCheckHeight}px` }}
      />
      
      <div ref={securityCheckRef} style={{ flex: 1 }}>
        <div className="security-check">
          <h4>Security Check</h4>
          <div className="security-row">
            <span className="security-label">NIK</span>
            <SecurityResult value={item.securityCheck.identifier}/>
          </div>
          <div className="security-row">
            <span className="security-label">Nama</span>
            <SecurityResult value={item.securityCheck.name}/>
          </div>
          <div className="security-row">
            <span className="security-label">Tgl. Lahir</span>
            <SecurityResult value={item.securityCheck.birthDate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

const Verified = () => (
  <div className="align-content-center">
    <span className="me-2">Verified by</span>
    <img src={require("../../assets/images/logo/logo-verihubs.png")} style={{ height: '15px' }}/>
  </div>
)

const VerificationCard = ({ item, index }) => {
  return (
    <>
      <p className='card-title'>ID {index + 1} • {item.name}</p>

      <Card className="verify-card-container">
        <CardHeader className="d-flex card-no-border justify-content-between pb-0">
          <label className="header">BUKTI VERIFIKASI</label>

          {item.isVerified && <Verified />}
        </CardHeader>

        <CardBody>
          <Row>
            <Col>
              <label>Verification Date</label>
              <p>{item.verificationDate}</p>
            </Col>

            <Col className="text-end">
              <label>Verification Code</label>
              <p>{item.verificationCode}</p>
            </Col>
            
            <ItemSeparator />

            <ProfileCard item={item} />
            <SecurityCheck item={item} />
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

const CertificateIdentifier = ({ verifyResult }) => {
  return (
    <>
      <Card className="col-sm-12 mt-3">
        <CardBody>
          {verifyResult.map((item, index) =>
              item.isVerified
              ? (<p key={index} className="d-flex align-items-center"><i className="fa fa-check-circle security-value-success me-2" style={{ fontSize: '18px' }}></i> ID {item.name} berhasil diverifikasi</p>)
              : (<p key={index} className="d-flex align-items-center"><i className="fa fa-times-circle security-value-failed me-2" style={{ fontSize: '18px' }}></i> ID {item.name} gagal diverifikasi</p>)
          )}
        </CardBody>
      </Card>

      <Card className="col-sm-12 mt-3">
        <CardHeader>
          <h3>Bukti Verifikasi</h3>
        </CardHeader>

        <CardBody>
          {verifyResult.map((item, index) =>
            <VerificationCard key={index} item={item} index={index} />
          )}
          
        </CardBody>
      </Card>
    </>
  );
};

const getBase64FromUrl = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error fetching image:", error);
    throw error;
  }
};

const VerifyIdentifier = () => {
  const { setHeaderMenu } = useContext(CustomizerContext);

  const [isVerifyID, setIsVerifyID] = useState(false);
  const [chosenIDs, setChosenIDs] = useState([]);

  const [verifyResult, setVerifyResult] = useState([]);
  const [isProcessingVerify, setIsProcessingVerify] = useState(false);

  const [dataOri, setDataOri] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setHeaderMenu('Verify Result')

    API.ktp.getListKtp()
      .then(res => {
        const result = res.data.filter(el => el.verified_status === false).map(el => ({
          value: el.id,
          label: el.name
        }));
        setData(result)
        setDataOri(res.data)
      })
      .catch(x => alert(x))
  }, [])

  const handleVerifyKtp = (dataKtp) => {
    setIsProcessingVerify(true)
    console.log('handleVerifyKtp', dataKtp)
    console.log('chosenIDs', chosenIDs)
    

    getBase64FromUrl(dataKtp.preview)
      .then((base64String) => {
        API.ktp.verifyKtp({
          identifierId: chosenIDs[0],
          selfieBase64: base64String,
        }).then((res) => {
          console.log(res);
          setIsProcessingVerify(false)
          setVerifyResult([res.data])
          setIsVerifyID(res.data.isVerified)
        });
      })
      .catch((error) => console.error("Error converting image:", error)); 
  }

  return (
    <Fragment>
      <Container
        fluid={true}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        {data.length > 0 && !isVerifyID && !isProcessingVerify && (
          <Upload
            title="Upload & Scan ID"
            subtitle="Kamu bisa melakukan Scan ID lebih dari satu file"
            type="verify"
            data={data}
            dataOri={dataOri}
            onChangeDropdown={(id) => setChosenIDs([id])}
            onSubmit={(a) => handleVerifyKtp(a[0])}
          />
        )}

        {isProcessingVerify && (
          <Loading
            title="Verifying ID"
            body="Wait a moment"
          />
        )}

        {isVerifyID && (
          <CertificateIdentifier verifyResult={verifyResult}/>
        )}
      </Container>
    </Fragment>
  );
};

export default VerifyIdentifier;

const styles = {
  separator: {
    borderBottom: '1px solid #e9e9e9',
    marginBottom: '1rem',
    paddingBottom: '1rem',
  },
}